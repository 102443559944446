import { useEffect, useState } from "react";
import { ASSETS } from "../../game/util/assets";
import { useStoreState } from "../../game/util/hook";

const ProgressBar = ({ totalScreen }: { totalScreen: number }) => {
  const [displayProgress, setDisplayProgress] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setDisplayProgress(totalScreen);
    }, 500);
  }, [totalScreen]);

  const limitProgress = (displayProgress: number) => {
    const minProgress = 10;
    const maxProgress = 100;

    if (displayProgress >= minProgress && displayProgress <= maxProgress) {
      return displayProgress;
    }
    if (displayProgress > maxProgress) {
      return maxProgress;
    } else {
      return minProgress;
    }
  };

  return (
    <>
      <div className="flex bg-[#F0F0F2] drop-shadow-md w-[70%] h-5 rounded-full mt-[4rem] relative ">
        <div
          className="h-full rounded-full bg-[#FEBC11] w-0 transition-all duration-[1000ms] ease-in-out relative  "
          style={{
            width: `${limitProgress(displayProgress)}%`,
          }}
        ></div>
        <img
          src={`${ASSETS.SHINHAN_CHARACTER}`}
          className="h-14 absolute -mt-16 -ml-14 duration-1000 ease-in-out"
          style={{
            left: `${limitProgress(displayProgress)}%`,
          }}
        />
        <div className="-mt-[3.45rem] z-20 -ml-[2.9rem] font-bold text-xs text-white">
          {totalScreen < 10 ? "0" + totalScreen : totalScreen}
        </div>
        <img
          src={`${ASSETS.PROGRESS_PERCENT_BUTTON}`}
          className="h-full absolute -ml-[1.625rem] duration-1000 ease-in-out"
          style={{
            left: `${limitProgress(displayProgress)}%`,
          }}
        />
      </div>
    </>
  );
};

export default ProgressBar;
