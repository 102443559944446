/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import Modal from "../../Modal";
import { ASSETS } from "../../../game/util/assets";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  zIndex?: number;
  data: any[];
}

const VoucherGift = ({ closeModal }: { closeModal: () => void }) => {
  return (
    <div className="flex w-[75%] max-w-xs flex-col justify-center items-center">
      <img src="uiAsset/GiftVoucherPopup.png" onClick={closeModal} />
      <div className="absolute">
        <img src="uiAsset/EV_1M.png" className="h-24" />
      </div>
    </div>
  );
};

export const TotalRankPopup = ({
  isOpen,
  closeModal,
  zIndex,
  data,
}: ModalProps) => {
  const [showVoucherPopup, setShowVoucherPopup] = useState(false);

  return (
    <Modal
      visible={isOpen}
      onDismiss={closeModal}
      dismissOnBackdropClick={false}
      zIndex={zIndex}
      backdropColor="rgba(0, 0, 0, 0.8)"
    >
      {!showVoucherPopup ? (
        <div className="flex w-[75%] max-w-xs flex-col">
          <img
            src="uiAsset/NoticeTotalRankPopup.png"
            onClick={() => setShowVoucherPopup(true)}
          />
          <div className="absolute flex w-[75%] justify-center items-center mt-[30%] max-w-xs">
            <img
              src="uiAsset/NametagUser.png"
              className="absolute w-[85%] h-7"
            />
            <div className="flex w-[80%] h-full z-10 text-sm">
              <div className="font-bold text-[#002DAA] ml-1">1123.</div>
              <div className="flex">
                <img
                  src="uiAsset/FlagLevelIcon.png"
                  className="absolute w-6 ml-1 -mt-1 z-10"
                />
                <div className="z-50 text-white shadow-lg text-[11px] font-[600] -mt-0.5 ml-2.5">
                  90
                </div>
              </div>
              <div className="ml-2 text-[#002DAA] font-bold w-[55%]">Yours</div>
              <div className="text-[#002DAA] -mt-0.5 font-bold flex items-center">
                1000{" "}
                <span>
                  <img src={ASSETS.POINT_SCORE} className="w-6 ml-0.5 " />
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <VoucherGift closeModal={closeModal} />
      )}
    </Modal>
  );
};
