import { Vector2 } from "gdxts";
import { dimensionInfo } from "../Constants";
import { GameManager } from "../initGame";
import { Line } from "../types";
import { emitter } from "../util/emitter";
import {
  calculateGridPos,
  calculateLine,
  calculateLineCap,
} from "../util/gridHelper";
import { SoundFxId, soundUtils } from "../util/soundUtil";
import { Grid } from "../util/word-search/grid";

export const registerInputSystem = (manager: GameManager) => {
  const inputHandler = manager.context.inputHandler;
  const state = manager.context.state;
  const gameConfigs = manager.context.gameConfigs;

  manager.addSystem(() => {
    const startPos = new Vector2();
    const endPos = new Vector2();
    const oldEndPos = new Vector2();

    const tmpV1 = new Vector2();
    const tmpV2 = new Vector2();
    const wordsAnswered: string[] = [];
    emitter.on("newQuestions", (question) => {
      wordsAnswered.length = 0;
    });

    const getCurrentWord = (grid: Grid, line: Line) => {
      let word = "";
      tmpV1.set(line.x1, line.y1);
      tmpV2.set(line.x2, line.y2);
      const fullLine = calculateLine(tmpV1, tmpV2);
      for (const { x, y } of fullLine) {
        word += grid[y][x];
      }
      return word;
    };

    return {
      process() {
        if (state.grid === undefined || state.originalWords === undefined) {
          return;
        }
        if (inputHandler.isTouched()) {
          const { x, y } = inputHandler.getTouchedWorldCoord();
          const gridPos = calculateGridPos(x, y);
          if (!state.dragging) {
            state.dragging = true;
            startPos.setVector(gridPos);
            endPos.setVector(gridPos);
            state.currentLineDirty = true;
          }
          oldEndPos.setVector(endPos);
          endPos.setVector(gridPos);
          if (endPos.x !== oldEndPos.x || endPos.y !== oldEndPos.y) {
            state.currentLineDirty = true;
          }
          state.currentLine = {
            x1: startPos.x,
            y1: startPos.y,
            x2: endPos.x,
            y2: endPos.y,
          };
        } else {
          if (state.dragging) {
            state.dragging = false;
            if (
              state.currentLine.x1 < 0 ||
              state.currentLine.x2 < 0 ||
              state.currentLine.y1 < 0 ||
              state.currentLine.y2 < 0 ||
              state.currentLine.x1 >= dimensionInfo.dimensions.COL ||
              state.currentLine.x2 >= dimensionInfo.dimensions.COL ||
              state.currentLine.y1 >= dimensionInfo.dimensions.ROW ||
              state.currentLine.y2 >= dimensionInfo.dimensions.ROW
            ) {
              return;
            }
            const word = getCurrentWord(state.grid, state.currentLine);
            let index = state.originalWords.indexOf(word);
            if (index > -1) {
              const line = calculateLineCap(startPos, endPos, {
                ...state.currentLine,
              });
              for (let i = 0; i < state.foundLines.length; i++) {
                if (
                  state.foundLines[i].x1 === line.x1 &&
                  state.foundLines[i].x2 === line.x2 &&
                  state.foundLines[i].y1 === line.y1 &&
                  state.foundLines[i].y2 === line.y2
                ) {
                  return;
                }
              }
              state.foundLines.push(line);
              for (let i = index + 1; i < state.originalWords.length; i++) {
                console.log(state.originalWords[i], word);
                if (
                  state.originalWords[i] === word &&
                  wordsAnswered.indexOf(word) !== -1
                ) {
                  index++;
                }
              }
              if (state.foundLines.length >= 3) {
                manager.context.currentGame.ended = true;
              }

              emitter.emit("answer", index, word);
              wordsAnswered.push(word);

              gameConfigs.score += gameConfigs.scorePerAnswer;

              emitter.emit("correctAnswer", gameConfigs.score);
            } else {
              soundUtils.play(SoundFxId.WRONG_ANSWER);
            }
          }
        }
      },
    };
  });
};
