import * as uuid from "uuid";
import config from "../../config";
import gamefoxSDK, { ItemUsageType, MockupData } from "./gameFoxSDK";

const mockupData: Partial<MockupData> = {
  user: {
    _id: "sampleUser",
    data: {
      username: "PHAM TUAN VU PHAM ",
    },
    progressData: {
      loginStreak: 1,
      wordSearchLevel: 7,
      wordSearchPassLvl: 6,
      wordSearchScore: 13,
      wordSearchWeekScore: 13,
    },
  },
  reward: {
    itemId: "test",
    itemType: "VOUCHER",
  },
  leaderboard: [],
  notifications: [],
  playLog: {},
};
const defaultMockupData = gamefoxSDK.getMockupData();
defaultMockupData.items.push({
  id: uuid.v4(),
  type: "VOUCHER",
  usageType: ItemUsageType.ITEM,
  data: {},
});
mockupData.items = defaultMockupData.items;
mockupData.playLog!.questions = defaultMockupData.playLog.questions;

const names = [
  "Henrietta Kilback Henrietta Kilback",
  "Ella Champlin",
  "Patty West IV",
  "Santiago Friesen",
  "Henrietta Kilback",
  "Ella Champlin",
  "Patty West IV",
  "Santiago Friesen",
  "Henrietta Kilback",
  "Ella Champlin",
  "Patty West IV",
  "Santiago Friesen",
];

for (let i = 0; i < 30; i++) {
  mockupData.leaderboard!.push({
    _id: uuid.v4(),
    data: { username: names[i] },
    progressData: {
      totalScore: Math.floor(Math.random() * 1000),
      currentWeekScore: Math.floor(Math.random() * 1000),
    },
  });
}

const _DEV_TEMPLATE_KEY = "6530b254c34afc0910f4af2e";
const _DEV_CAMPAIGN_ID = "6530b254c34afc0910f4af2d";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _DEV_INSET = "44;0;31;0";
// const _DEV_TOKEN =
//   "eyJhbGciOiJSUzI1NiIsImFscGhhIjpmYWxzZX0.eyJjdXN0b21lcklkIjoidGVzdF91c2VyXzIifQ.DRuSDfeEvMzZ87QyWq4Fat1l1-a5sr9ISNohBTGqhVvnGuIXB6jtXAto6SC7NCVyh3rNHNPjTt34Q65BERXYDC-LJaOzGrXRVfv04MdVE54rU0Z9kQd7E77kdiD2WX5u0mZCG4ghziez96JZwSdqYc-ZndASnvRSt5MJy4h2Mb1rGwNDL67-qFbG5ED6pvCdGvQq-Nc0LqtB7u6UWmvV9GPz_65VS9wl39QZiUzf1CMPCiU8wyYkCSHfsgncsdAEH4sUJcL5Tg_LSb_68QtzrNNur1VvSYdRmfP_33Jex3Iwrl_0x0HHAjPYanubsIuBjs8CKNtBSs360fi0Kw1WSw";
const _DEV_TOKEN =
  "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJ0ZXN0IiwiZXhwIjoxNzA2NDIwNjUwLCJpc3MiOiJzaGluaGFuIiwiY3VzdG9tZXJJZCI6IjI4MjA3NTQxODYiLCJtYWlsIjoiMjgyMDc1NDE4NkBURVNULkNPTSIsInBob25lTnVtYmVyIjoiMDk4MTIwNzU0MSIsImRhdGEiOnsidXNlcm5hbWUiOiJDVVMtU05NLU5NMjgyMDc1NDE4NiIsIm5ld1VzZXIiOnRydWV9fQ.MYTKwu4AMpXz_04uUcbY3e8DmJtdwzW3XCYuwOtosWkjiqaRJLwjOair2eoMdh9GdP3_bQM-Sw7A1ETwvO5epiVDS9z7j9kcA2LkqR77XaYieV5UST3NlAHgN6fCxbhEsyH4eF6nolJxYpVENncCebkHPe1g2D-sWQm97FO0yuDkIe06C8dqc2Ccc8NXcfKWispX4y6vp2n3H6oMHytGmcthK_Fhpn2Rf7m5nAlufchmWSokue9PvqwnNnmWJWtMicZZBZtQ10dkXll3mCuUH8lpBGEatnmYg-So8cl6k7jfODs3hQtzLZg3eQnMNLIc81sZ6PdtwKmtyITSQmsxeA";

const initSdk = async () => {
  if (config.online && config.local) {
    window.history.pushState(
      "Home",
      "Title",
      `/?templateKey=${_DEV_TEMPLATE_KEY}&campaignId=${_DEV_CAMPAIGN_ID}&token=${_DEV_TOKEN}`
    );
  }

  if (!config.online) {
    window.history.pushState(
      "Home",
      "Title",
      `/?templateKey=${_DEV_TEMPLATE_KEY}&campaignId=${_DEV_CAMPAIGN_ID}&token=${_DEV_TOKEN}&inset=${_DEV_INSET}`
    );
    gamefoxSDK.setMockupData(mockupData);
  }
  await gamefoxSDK.init(config.apiUrl, !config.online);
};

export default initSdk;
