import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ASSETS, cacheAssets, loadImage } from "../../game/util/assets";
import { soundUtils } from "../../game/util/soundUtil";
import {
  useRefreshInventory,
  useRefreshNotifications,
  useRefreshUser,
  useStoreActions,
  useStoreState,
} from "../../game/util/hook";
import { useMediaQuery } from "react-responsive";
import { getInstructViewed } from "../../game/util/storage";

const Loading = () => {
  const navigate = useNavigate();
  const getInstruct = getInstructViewed();
  const setInstructMode = useStoreActions((actions) => actions.setInstructMode);

  const tabletMode = useMediaQuery({
    query: "(min-width: 650px)",
  });
  const setTabletMode = useStoreActions((actions) => actions.setTabletMode);

  const refreshInventory = useRefreshInventory();
  const refreshUser = useRefreshUser();
  const refreshNotifications = useRefreshNotifications();
  const user = useStoreState((state) => state.user);

  useEffect(() => {
    if (!user?.progressData.loginStreak) {
      setInstructMode(true);
    } else {
      setInstructMode(false);
    }
  }, [getInstruct, setInstructMode, user]);

  const init = useCallback(async () => {
    await cacheAssets();
    await refreshUser();
    await refreshInventory();
    await refreshNotifications();

    setTabletMode(tabletMode);
    if (tabletMode) {
      await loadImage("uiAsset/MainBigScreen.png");
    }
    await soundUtils.initSounds("./sounds/");

    navigate({ pathname: "/play", search: window.location.search });
  }, [
    navigate,
    refreshInventory,
    refreshNotifications,
    refreshUser,
    setTabletMode,
    tabletMode,
  ]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <div className="flex flex-col relative flex-1 w-full h-full justify-center items-center ">
        <div className="w-full max-w-xl h-full flex flex-col items-center justify-center  ">
          <div className="w-full">
            {!tabletMode ? (
              <img src="./uiAsset/LoadingScreenBackground.png" className=" " />
            ) : (
              <div className="flex flex-col items-center h-full ">
                <img
                  src="./uiAsset/LoadingScreenBigBackground.png"
                  className="h-full scale-[1.4] scale-y-[1.7] "
                />
                <img
                  src={`${ASSETS.GAME_NAME}`}
                  className="absolute ml-[2vw] w-[60%]"
                />
              </div>
            )}
          </div>
          <div className="w-full h-full relative flex items-center justify-center ">
            <div className="w-full flex flex-col items-center absolute bottom-12">
              <img src="./uiAsset/WaitLoadingText.png" className="h-4 " />
              <div className="bg-[#DFEBFF] w-[60%] rounded-lg h-4 mt-2 overflow-x-hidden">
                <div
                  className=" bg-gradient-to-b from-[#FFE673] to-[#FF9905] progressLoadingBar h-4 rounded-lg "
                  style={{ width: "35%" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loading;
