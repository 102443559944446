import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://37d231491f4af8f10e788f3ba061b0a9@o4506596704452608.ingest.sentry.io/4506596706091008",
  integrations: [],
});

ReactDOM.createRoot(document.getElementById("root")!).render(<App />);
