/* eslint-disable @typescript-eslint/no-explicit-any */
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { emitter } from "../../game/util/emitter";
import { initGame } from "../../game/initGame";
import { ASSETS } from "../../game/util/assets";
import { GiftBulbPopup } from "../popup/GiftBulbPopup";
import { GiftPaperPlanePopup } from "../popup/GiftPaperPlanePopup";
import { TimesUpPopup } from "../popup/TimesUpPopup";
import { NoticeEndgamePopup } from "../popup/NoticeEndgamePopup";
import { Question } from "../../game/types";
import {
  useRefreshInventory,
  useRefreshUser,
  useStoreActions,
  useStoreState,
} from "../../game/util/hook";
import gamefoxSDK from "../../game/util/gameFoxSDK";
import { SoundFxId, soundUtils } from "../../game/util/soundUtil";
import { ConfirmSkipLevelPopup } from "../popup/ConfirmSkipLevelPopup";
import { useNavigate } from "react-router-dom";
import config from "../../config";

type OverlayInfo = {
  x: number;
  y: number;
};

export const Game = ({
  mode,
  instructGame,
  setCountTimeSound,
}: {
  mode: "10x10";
  instructGame: boolean;
  setCountTimeSound: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const ref = useRef<HTMLCanvasElement>(null);
  const user = useStoreState((state) => state.user);
  const navigate = useNavigate();

  const inventory = useStoreState((state) => state.inventory);
  const gameConfigs = useStoreState((state) => state.gameConfigs);
  const gotHint = useStoreState((state) => state.gotHint);
  const setGotHint = useStoreActions((state) => state.setGotHint);

  const [idGame, setIdGame] = useState("");

  const [openGiftBulbPopup, setOpenGiftBulbPopup] = useState(false);
  const [openGiftPaperPlanePopup, setOpenGiftPaperPlanePopup] = useState(false);
  const [openTimesUpPopup, setOpenTimesUpPopup] = useState(false);
  const [openNoticeEndgamePopup, setOpenNoticeEndgamePopup] = useState(false);

  const [questions, setQuestions] = useState([]);
  const [answered, setAnswered] = useState([]);
  const [wordsAnswered, setWordsAnswered] = useState([]);
  const [countAnswered, setCountAnswered] = useState(0);
  const [winningScreen, setWinningScreen] = useState(false);
  const [totalAnswers, setTotalAnswers] = useState(3);

  const [countUseSuggestItem, setCountUseSuggestItem] = useState(0);
  const [openNoticeConfirmSkipLevel, setOpenNoticeConfirmSkipLevel] =
    useState(false);

  const [animCorrectWord, setAnimCorrectWord] = useState<any>(null);
  const [showAnimCorrectWord, setShowAnimCorrectWord] = useState(false);

  const [animSuggestWord, setAnimSuggestWord] = useState<any>(null);
  const [showAnimSuggestWord, setShowAnimSuggestWord] = useState(false);

  const [overlayInfo, setOverlayInfo] = useState<OverlayInfo[]>([]);

  const [animSkipLevel, setAnimSkipLevel] = useState<any>(null);
  const [showAnimSkipLevel, setShowAnimSkipLevel] = useState(false);

  const [activeSuggestWord, setActiveSuggestWord] = useState(false);
  const [activeItems, setActiveItem] = useState(false);

  const refreshUser = useRefreshUser();
  const refreshInventory = useRefreshInventory();

  const [initedScreen, setInitedScreen] = useState(false);
  const setInstructMode = useStoreActions((actions) => actions.setInstructMode);

  useEffect(() => {
    if (!user?.progressData.wordSearchScore) {
      gameConfigs.score = 0;
    }
  }, [gameConfigs, user?.progressData.wordSearchScore]);

  useEffect(() => {
    if (!instructGame) {
      return;
    }
    const handleLoadDone = () => {
      gameConfigs.score = 0;
      setTimeout(() => {
        emitter.emit("onInstructMode");
      }, 500);
    };
    emitter.on("loadDone", handleLoadDone);

    return () => {
      emitter.off("loadDone", handleLoadDone);
    };
  }, [gameConfigs, instructGame]);

  useEffect(() => {
    if (!instructGame) {
      return;
    }

    const handleOffInstruct = () => {
      setInstructMode(false);
    };
    emitter.on("offInstructMode", () => handleOffInstruct);
    return () => {
      emitter.off("offInstructMode", () => handleOffInstruct);
    };
  }, [instructGame, setInstructMode]);

  useEffect(() => {
    const handler = (x: number, y: number) => {
      setShowAnimSuggestWord(true);
      gameConfigs.suggestItem -= 1;
      setOverlayInfo((prev) => [
        ...prev,
        {
          x,
          y,
        },
      ]);
    };
    emitter.on("showOverlay", handler);
    return () => {
      emitter.off("showOverlay", handler);
    };
  }, [gameConfigs]);

  useEffect(() => {
    import("../../data/lottie/CorrectAnswers.json").then((module) =>
      setAnimCorrectWord(module.default)
    );
    import("../../data/lottie/Suggest.json").then((module) =>
      setAnimSuggestWord(module.default)
    );
    import("../../data/lottie/Skip.json").then((module) =>
      setAnimSkipLevel(module.default)
    );
  }, []);

  const refreshGame = useCallback(async () => {
    await gamefoxSDK.auth();
    refreshInventory();
  }, [refreshInventory]);

  useEffect(() => {
    refreshGame();
  }, [refreshGame]);

  useEffect(() => {
    if (!initedScreen) {
      try {
        gameConfigs.totalScreenPlayed =
          user?.progressData?.wordSearchPassLvl | 0;
        // Object.values(inventory?.resources).map((idItem) => {
        //   if (idItem?.type === "SKIP") {
        //     gameConfigs.skipItem = idItem?.amount | 0;
        //   }
        //   if (idItem?.type === "SUGGEST") {
        //     gameConfigs.suggestItem = idItem?.amount | 0;
        //   }
        // });
        if (inventory?.resources) {
          if (
            inventory?.resources?.["655b0d070b37243cc9b4a2ec"].type === "SKIP"
          ) {
            gameConfigs.skipItem =
              inventory?.resources?.["655b0d070b37243cc9b4a2ec"].amount || 0;
          }
          if (
            inventory?.resources?.["655b0d070b37243cc9b4a2ef"].type ===
            "SUGGEST"
          ) {
            gameConfigs.suggestItem =
              inventory?.resources?.["655b0d070b37243cc9b4a2ef"]?.amount || 0;
          }
        }
        setInitedScreen(true);
      } catch (e) {
        setInitedScreen(false);
        throw new Error(e);
      }
    }
  }, [gameConfigs, initedScreen, inventory, user]);

  useEffect(() => {
    if (gotHint) {
      gameConfigs.suggestItem += 3;
      setGotHint(false);
    }
  }, [gameConfigs, gotHint, setGotHint]);

  useEffect(() => {
    const handleDisableItem = (time: number) => {
      if (time <= 5) {
        setActiveItem(false);
      }
      if (time > 5 && time < 115 && !activeItems) {
        setActiveItem(true);
      }
    };

    emitter.on("setTimeLeft", handleDisableItem);
    return () => {
      emitter.off("setTimeLeft", handleDisableItem);
    };
  });

  useEffect(() => {
    const timeupCallback = async () => {
      await gamefoxSDK.submitPlay(idGame, wordsAnswered, 0);
      const userData = await gamefoxSDK.auth();

      setOpenTimesUpPopup(true);
      gameConfigs.score = userData.progressData.wordSearchScore;
    };
    emitter.on("noticeTimesUp", timeupCallback);
    return () => {
      emitter.off("noticeTimesUp", timeupCallback);
    };
  }, [gameConfigs, idGame, wordsAnswered]);

  useEffect(() => {
    const handleRenewGame = async () => {
      setCountTimeSound(false);
      const questionsGame = await gamefoxSDK.getQuestion();
      refreshUser();
      gameConfigs.topic = questionsGame.data.topic
        ? questionsGame.data.topic
        : questionsGame.topic;
      console.log("reloadgame", questionsGame);
      setIdGame(questionsGame.data._id);
      setQuestions(questionsGame.data.questions);
      emitter.emit("newQuestions", questionsGame.data.questions);
      setAnswered([]);
      setOverlayInfo([]);
      setWordsAnswered([]);
      setCountUseSuggestItem(0);
      setCountAnswered(0);
      setShowAnimCorrectWord(false);
      setWinningScreen(false);
    };

    emitter.on("renewQuestions", handleRenewGame);

    return () => {
      emitter.off("renewQuestions", handleRenewGame);
    };
  }, [gameConfigs, refreshUser, setCountTimeSound]);

  const handleSubmitGame = useCallback(() => {
    if (countAnswered === totalAnswers) {
      setTimeout(async () => {
        emitter.emit("onGameEnded");
        const timeLeft = gameConfigs.timeRemaining;
        setAnswered((prev) => {
          prev[0] = true;
          return [...prev];
        });
        setAnswered((prev) => {
          prev[1] = true;
          return [...prev];
        });
        setAnswered((prev) => {
          prev[2] = true;
          return [...prev];
        });
        const gameEnded = await gamefoxSDK.submitPlay(
          idGame,
          wordsAnswered,
          timeLeft
        );
        gameConfigs.scoreInRound = gameEnded?.score;
        const userData = await gamefoxSDK.auth();
        gameConfigs.score = userData.progressData.wordSearchScore;
        if (gameEnded.isPass || config.local) {
          setOpenNoticeEndgamePopup(true);
        }
        if (gameEnded?.rewards[0]?.itemType === "SKIP") {
          gameConfigs.skipItem += gameEnded?.rewards[0]?.amount;
          setOpenGiftPaperPlanePopup(true);
        }
      }, 3000);
    }
  }, [countAnswered, gameConfigs, idGame, totalAnswers, wordsAnswered]);

  useEffect(() => {
    handleSubmitGame();
  }, [handleSubmitGame]);

  useEffect(() => {
    const handleAnswer = (index: number, answer: string) => {
      setShowAnimCorrectWord(true);
      setWordsAnswered((prev) => [...prev, answer]);
      setAnswered((prev) => {
        prev[index] = true;
        return [...prev];
      });

      setCountAnswered((prev) => prev + 1);

      setTimeout(() => {
        setShowAnimCorrectWord(false);
      }, 3000);
    };
    soundUtils.play(SoundFxId.CORRECT_ANSWER);

    emitter.on("answer", handleAnswer);
    return () => {
      emitter.off("answer", handleAnswer);
    };
  }, [wordsAnswered]);

  const handleRenewMap = async () => {
    if (!gameConfigs.skipItem || instructGame) {
      return;
    }

    setCountTimeSound(false);
    setOverlayInfo([]);

    try {
      const userData = await gamefoxSDK.auth();
      setShowAnimSkipLevel(true);
      gameConfigs.skipItem -= 1;
      const data = await gamefoxSDK.getQuestion();
      setTimeout(() => {
        gameConfigs.totalScreenPlayed += 1;
        setIdGame(data?.data?._id);
        gameConfigs.topic = data?.data?.topic ? data?.data?.topic : data?.topic;
        emitter.emit("pauseGame", false);
        setQuestions(data?.data?.questions);
        emitter.emit("newQuestions", data?.data?.questions);
        setAnswered([]);
        setWordsAnswered([]);
        setOverlayInfo([]);
        setCountAnswered(0);
        setCountUseSuggestItem(0);
        setShowAnimSkipLevel(false);
        setWinningScreen(false);
        gameConfigs.score = userData?.progressData?.wordSearchScore || 0;
      }, 2000);
    } catch (e) {
      throw new Error(e);
    }
  };

  const handleSuggestWord = async () => {
    if (
      gameConfigs.ignoreSuggestWord.length >= 3 ||
      instructGame ||
      !gameConfigs.suggestItem ||
      !activeSuggestWord ||
      !activeItems
    ) {
      return;
    }

    if (!config.online) {
      emitter.emit("suggest");
      setCountUseSuggestItem((prev) => prev + 1);
    }
    setActiveSuggestWord(false);
    const useSuggest = await gamefoxSDK.useSuggestItem();
    if (!useSuggest) {
      return;
    }
    emitter.emit("suggest");
    setCountUseSuggestItem((prev) => prev + 1);
    setTimeout(() => {
      setActiveSuggestWord(true);
    }, 1500);
  };

  const handleNextLevel = async () => {
    setCountTimeSound(false);
    const questionsGame = await gamefoxSDK.getQuestion();
    gameConfigs.topic = questionsGame.data.topic
      ? questionsGame.data.topic
      : questionsGame.topic;

    console.log("dataquestion", questionsGame);
    setIdGame(questionsGame.data._id);
    setQuestions(questionsGame.data.questions);
    emitter.emit("newQuestions", questionsGame.data.questions);
    emitter.emit("nextLevel");
    setAnswered([]);
    setOverlayInfo([]);
    setWordsAnswered([]);
    setCountUseSuggestItem(0);
    setCountAnswered(0);
    setShowAnimCorrectWord(false);
    setWinningScreen(false);
  };

  const handleAcceptedNoticeSkipLevel = async () => {
    setOpenNoticeConfirmSkipLevel(false);
    const useSkip = await gamefoxSDK.useSkipItem();
    if (useSkip) {
      handleRenewMap();
    }
  };

  useEffect(() => {
    if (!ref.current) return;
    const gamePromise = initGame(ref.current, gameConfigs).then(
      async (game) => {
        let questionsGame = await gamefoxSDK.getQuestion();
        // setTopic(data.topic);
        gameConfigs.ignoreSuggestWord = [];
        gameConfigs.correctAllAnswersPos = [];
        setCountUseSuggestItem(0);
        setCountAnswered(0);
        setAnswered([]);
        setWordsAnswered([]);
        gameConfigs.topic = questionsGame.data.topic
          ? questionsGame.data.topic
          : questionsGame.topic;
        setTimeout(() => {
          setActiveSuggestWord(true);
        }, 3000);

        if (!questionsGame?.data?.question) {
          questionsGame = await gamefoxSDK.getQuestion();
        }
        console.log(questionsGame);
        setTimeout(() => {
          setIdGame(questionsGame.data._id);
          setQuestions(questionsGame.data.questions);
          emitter.emit("newQuestions", questionsGame?.data?.questions);
        }, 1500);

        return game;
      }
    );
    return () => {
      gamePromise.then((game) => game.dispose());
    };
  }, [gameConfigs, mode, setInstructMode]);

  return (
    <>
      <div className="flex w-full h-full flex-1 mt-6  justify-center ">
        <div className="text-center text-xl font-bold pit top-4 z-50  text-[#193FA8] absolute">
          Screen:{" "}
          {gameConfigs.totalScreenPlayed < 10
            ? "0" + gameConfigs.totalScreenPlayed
            : gameConfigs.totalScreenPlayed}
        </div>
        <div className="flex flex-col w-full items-center -mt-10">
          <div className="w-full h-[65.5%] -mt-2 relative">
            <div className="absolute w-full pointer-events-none ">
              {overlayInfo.map((overlay, index) => (
                <div
                  className="absolute min-w-[24.25rem] z-[99] pointer-events-none"
                  key={index}
                >
                  <Lottie
                    animationData={animSuggestWord}
                    autoPlay
                    className="relative z-[99] pointer-events-none"
                    style={{
                      left: -210 + overlay.x,
                      top: -390 + overlay.y,
                    }}
                  />
                </div>
              ))}
            </div>

            <canvas className="w-full h-full z-0" ref={ref}></canvas>
          </div>

          {showAnimCorrectWord && animCorrectWord !== null && (
            <div className=" absolute flex -mt-14 z-[99] ">
              <Lottie animationData={animCorrectWord} autoPlay />
            </div>
          )}

          {showAnimSkipLevel && animSkipLevel !== null && (
            <div className=" absolute flex -mt-14 z-[99]">
              <Lottie animationData={animSkipLevel} autoPlay />
            </div>
          )}
          <div className="flex flex-col absolute bottom-[17.5vh]">
            <div className="z-50 h-10 flex w-full max-w-[25rem] justify-between mb-1.5 pr-2 pl-2 ">
              <div
                className="flex h-full items-center -mt-1.5"
                onClick={handleSuggestWord}
              >
                <img
                  src="uiAsset/LookingWordButton.png"
                  alt=""
                  className={`h-12 ${
                    (!activeSuggestWord || !activeItems) && "brightness-75"
                  }`}
                />
                <div
                  className={`text-md font-bold text-[#193FA8] absolute ml-[3.8rem] -mt-0.5 `}
                >
                  {gameConfigs.suggestItem < 10
                    ? "0" + gameConfigs.suggestItem
                    : gameConfigs.suggestItem}
                </div>
              </div>
              <div
                className="flex h-full items-center -mt-1.5"
                onClick={async () => {
                  if (!gameConfigs.skipItem || !activeItems) {
                    return;
                  }
                  setOpenNoticeConfirmSkipLevel(true);
                }}
              >
                <img
                  src="uiAsset/PaperPlaneButton.png"
                  alt=""
                  className={`h-12 ${!activeItems && "brightness-75"}`}
                />
                <div className="text-md font-bold text-[#193FA8] absolute ml-[3.8rem] -mt-0.5">
                  {gameConfigs.skipItem < 10
                    ? "0" + gameConfigs.skipItem
                    : gameConfigs.skipItem}
                </div>
              </div>
            </div>
            <div className="w-full h-[10vh] flex flex-col max-w-[25rem] ">
              <div className="flex justify-center mt-2">
                <img
                  src={`uiAsset/BearIconGame.png`}
                  alt=""
                  className="h-20 absolute -mt-[5.35rem] "
                />
                <img
                  src={`${ASSETS.QUESTIONS_BOARD}`}
                  alt=""
                  className={`w-full -mt-4 h-[23vh] z-10 ${
                    instructGame ? "brightness-50" : ""
                  }`}
                />
              </div>
              <div className="flex flex-col items-center  absolute w-full max-w-sm ml-1 gap-[1.7vh] z-30 pr-1">
                {questions?.map((question, index) => (
                  <motion.div
                    key={index}
                    initial={{
                      translateX: -100,
                      opacity: 0,
                    }}
                    animate={{
                      translateX: 0,
                      opacity: 1,
                    }}
                    transition={{ delay: index * 0.1 }}
                    className={`w-[97%] text-white text-[1.65vh] font-bold pl-1  ${
                      instructGame && index !== 0
                        ? "brightness-50"
                        : instructGame
                        ? "border-2 rounded-lg "
                        : ""
                    } `}
                  >
                    <div className="transition-colors duration-200 w-full h-full ">
                      {index + 1}. {question?.question}{" "}
                      <span className="text-blue-600">
                        (
                        {answered[index]
                          ? question?.answer
                          : question?.answer?.length + " characters"}
                        )
                      </span>
                    </div>
                  </motion.div>
                ))}
              </div>
              {winningScreen && (
                <div className="flex bottom-1 z-30 w-full justify-center -mt-1">
                  <img
                    src="uiAsset/NextScreenButton.png"
                    onClick={async () => {
                      handleNextLevel();
                    }}
                    className="h-[5vh]"
                  />
                </div>
              )}
            </div>
          </div>
          <GiftBulbPopup
            isOpen={openGiftBulbPopup}
            closeModal={() => setOpenGiftBulbPopup(false)}
            zIndex={999}
          />
          <GiftPaperPlanePopup
            isOpen={openGiftPaperPlanePopup}
            closeModal={() => setOpenGiftPaperPlanePopup(false)}
            zIndex={999}
          />
          <TimesUpPopup
            isOpen={openTimesUpPopup}
            closeModal={() => {
              setOpenTimesUpPopup(false);
              navigate({ pathname: "/play", search: window.location.search });
            }}
            zIndex={999}
          />
          <NoticeEndgamePopup
            isOpen={openNoticeEndgamePopup}
            closeModal={() => {
              setOpenNoticeEndgamePopup(false);
              setWinningScreen(true);
              soundUtils.play(SoundFxId.CLAIM_GIFT);
            }}
            zIndex={999}
          />
        </div>
      </div>
      <ConfirmSkipLevelPopup
        isOpen={openNoticeConfirmSkipLevel}
        closeModal={handleAcceptedNoticeSkipLevel}
        handleCancelSkip={() => {
          setOpenNoticeConfirmSkipLevel(false);
        }}
        zIndex={999}
      />
    </>
  );
};
