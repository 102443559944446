// ReusableModal.tsx
import React, { useEffect } from "react";

interface ReusableModalProps {
  dismissOnBackdropClick?: boolean;
  visible?: boolean;
  zIndex?: number;
  backdropColor?: string;
  onDismiss: () => void;
  onEntered?: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ReusableModalProps> = ({
  dismissOnBackdropClick = true,
  visible,
  zIndex = 999,
  backdropColor = "rgba(0, 0, 0, 0.9)",

  onDismiss,
  onEntered,
  children,
}) => {
  useEffect(() => {
    if (visible && onEntered) {
      onEntered();
    }
  }, [visible, onEntered]);

  const handleBackdropClick = () => {
    if (dismissOnBackdropClick) {
      onDismiss();
    }
  };

  return (
    <div
      className="fixed top-0  w-full h-full flex justify-center items-center z-10"
      style={{
        backgroundColor: backdropColor,
        display: visible ? "flex" : "none",
        zIndex,
      }}
      onClick={handleBackdropClick}
    >
      {children}
    </div>
  );
};

export default Modal;
