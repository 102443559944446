import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ASSETS } from "../../game/util/assets";
import { emitter } from "../../game/util/emitter";
import { useStoreState } from "../../game/util/hook";
import { AudioType, SoundFxId, soundUtils } from "../../game/util/soundUtil";
import {
  getMusicSetting,
  getSoundFXSetting,
  setMusicSetting,
  setSoundFXSetting,
} from "../../game/util/storage";

export const SystemGameRender = ({ hasCountTimeSound, setCountTimeSound }) => {
  const navigate = useNavigate();

  const [score, setScore] = useState(0);
  const gameConfig = useStoreState((state) => state.gameConfigs);
  const [timeRemaining, setTimeRemaining] = useState(120);

  const [isSoundFxOn, setIsSoundFxOn] = useState(true);
  const [isMusicOn, setIsMusicOn] = useState(true);

  const handleQuitGame = useCallback(() => {
    navigate({ pathname: "/play", search: window.location.search });
  }, [navigate]);

  useEffect(() => {
    const isEnableMusic = getMusicSetting();
    setIsMusicOn(isEnableMusic);
    const isEnableSoundFx = getSoundFXSetting();
    setIsSoundFxOn(isEnableSoundFx);
  }, []);

  const onUpdateMusicSetting = useCallback(async (on: boolean) => {
    if (soundUtils.mute(AudioType.MUSIC, !on)) {
      soundUtils.setMusicEnable(on);
      setMusicSetting(on);
      setIsMusicOn(on);
      // soundUtils.play("ThemeSound");
      soundUtils.muteAll(false);
    } else {
      soundUtils.muteAll(true);
      soundUtils.setMusicEnable(false);
      soundUtils.setSoundFxEnable(false);
    }
  }, []);

  const onUpdateSoundFXSetting = useCallback(async (on: boolean) => {
    if (soundUtils.mute(AudioType.SOUND_FX, !on)) {
      soundUtils.setSoundFxEnable(on);
      setSoundFXSetting(on);
      setIsSoundFxOn(on);
    }
  }, []);

  const onUpdateSound = useCallback(() => {
    const currentState = getMusicSetting();
    const newState = !currentState;
    onUpdateMusicSetting(newState);
    onUpdateSoundFXSetting(newState);
  }, [onUpdateMusicSetting, onUpdateSoundFXSetting]);
  useEffect(() => {
    emitter.on("setTimeLeft", (time) => {
      setTimeRemaining(Math.floor(time));
    });

    // if (timeRemaining <= 14 && !hasCountTimeSound) {
    //   soundUtils.play(SoundFxId.COUNT_TIMER_LEFT);
    //   setCountTimeSound(true);
    // }
    emitter.on("correctAnswer", setScore);

    return () => {
      emitter.off("setTimeLeft", (time) => {
        setTimeRemaining(Math.floor(time));
      });
      emitter.off("correctAnswer", setScore);
    };
  }, [timeRemaining, hasCountTimeSound, setCountTimeSound]);

  return (
    <div className="flex justify-between w-full  top-0 z-50 ">
      <div className="flex flex-col">
        <img
          src={`${ASSETS.GO_BACK_BUTTON}`}
          className="mt-2 w-10 h-10 ml-3"
          onClick={handleQuitGame}
        />
        <div className="mt-3">
          <img src={"uiAsset/PointBorder.png"} className="h-10 ml-3" />
          <div className="flex text-xl font-bold text-[#193FA8] absolute ml-[3.5rem] -mt-[2.05rem] w-[12.5%] max-w-[3rem]">
            <div>:</div>
            <div className="text-center w-full">
              {gameConfig.score < 10
                ? "0" + gameConfig.score
                : gameConfig.score}
            </div>
          </div>
        </div>
      </div>

      <div>
        <div onClick={onUpdateSound}>
          {isMusicOn === true ? (
            <img
              src={`${ASSETS.SOUND_ON_BUTTON}`}
              className="mt-2 w-10 h-10 mr-4"
            />
          ) : (
            <img
              src={`${ASSETS.SOUND_OFF_BUTTON}`}
              className="mt-2 w-10 h-10 mr-4"
            />
          )}
        </div>
        <div className="-ml-16">
          <img src={"uiAsset/BorderNone.png"} alt="" className="mt-3 h-10" />
          <div className="text-lg font-bold text-[#193FA8] absolute ml-[1rem] -mt-[2.15rem]">
            Time :{" "}
            {timeRemaining < 10
              ? "0" + Math.max(0, timeRemaining)
              : timeRemaining}
          </div>
        </div>
      </div>
    </div>
  );
};
