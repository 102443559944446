import { Texture } from "gdxts";
import { GameManager } from "../initGame";
import { ASSETS } from "../util/assets";
import { SCREEN_WIDTH } from "../Constants";
import { emitter } from "../util/emitter";

export const BorderGameRenderSystem = (manager: GameManager) => {
  manager.addSystem(async () => {
    const context = manager.context;
    const batch = manager.context.batch;
    const gameConfigs = manager.context.gameConfigs;
    const border = await Texture.load(context.gl, ASSETS.BORDER_GAME_PLAYING);
    let topicTexture = manager.context.assets.getTexture(
      gameConfigs.topic as never
    );

    const SCALE = 0.825;

    emitter.on("newQuestions", (question) => {
      const topic: string = gameConfigs.topic;
      topicTexture = manager.context.assets.getTexture(topic as never);
    });

    return {
      process() {
        batch.draw(border, 0, 0, border.width * SCALE, border.height * SCALE);
        if (topicTexture) {
          batch.draw(
            topicTexture,
            SCREEN_WIDTH / 2 - (topicTexture.width / 2) * SCALE,
            (topicTexture.height - 28) / SCALE,
            topicTexture.width * SCALE,
            topicTexture.height * SCALE
          );
        } else {
          // fking can't get provide texture
          topicTexture =
            manager.context.assets.getTexture("Provide infomation");
        }
      },
      dispose() {},
    };
  });
};
