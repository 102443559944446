export default {
  online: true,
  local: false,
  // apiUrl: "http://localhost:3004",
  // apiUrl: "https://shinhan-user-api-dev.gamefox.vn",
  apiUrl: "https://shinhan-user-api.gamefox.vn",
  enRuleLink:
    "https://shinhan.com.vn/public/uploads/CSD/2023/Game/TnC_Word%20search%20game_Nov2023_EN.pdf",
  viRuleLink:
    "https://shinhan.com.vn/public/uploads/CSD/2023/Game/TnC_Word%20search%20game_Nov2023_VI.pdf",
};
