import generateWordSearch from "./word-search/generate";
import { Grid } from "./word-search/grid";

export const generateWS = (
  words: string[],
  cols: number,
  rows: number,
  backward = false,
  diagonal = false
): [Grid, string[]] => {
  const gridGame = generateWordSearch({
    words,
    size: [cols, rows],
    fillBlanks: true,
    maxAttempts: 500000,
    allowBackwards: backward,
    allowDiagonals: diagonal,
  });

  const grid = gridGame.grid;

  return [grid, gridGame.placedWords];
};
