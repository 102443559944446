import { useCallback, useState } from "react";
import gamefoxSDK from "../../../game/util/gameFoxSDK";
import { useStoreState } from "../../../game/util/hook";
import dayjs from "dayjs";

export const YourPrices = ({ filterItem }) => {
  // TODO: data, add link to <span>
  const inventory = useStoreState((state) => state.inventory);

  const openExternalLink = useCallback((link: string) => {
    gamefoxSDK.postMessage({
      eventType: "LINK_CLICKED",
      eventData: {
        link,
      },
    });
  }, []);

  return (
    <div className="w-full absolute max-w-xs ml-1.5 max-h-[21rem] mt-12 overflow-scroll ">
      {inventory?.items.map((item, index) => (
        <div key={index}>
          {item?.data?.name === filterItem && (
            <div className="flex items-center pr-1 pb-2">
              <img src="uiAsset/GiftBoxIcon.png" className="h-14 " />
              <div className="text-sm self-start mt-1">
                <div className="text-blue-800 font-bold">
                  {item?.data?.name || ""}
                </div>
                <div className="text-gray-500 pr-1">
                  {" "}
                  Shinhan Bank gift you an eVoucher Got It valued{" "}
                  {item?.data?.price}
                  VND through "How to win customer smiles"
                </div>
                <div className="text-gray-500">
                  Please click on the{" "}
                  <span
                    className="text-blue-500"
                    onClick={() => openExternalLink(item?.data?.link || "")}
                  >
                    link{" "}
                  </span>
                  to use
                </div>
                <div className="text-blue-700 text-[0.625rem] mt-1">
                  Received:{" "}
                  {dayjs(item?.createdAt).add(-1, "day").format("DD/MM/YYYY")} |
                  Expiry date: {item?.data?.exp || ""}
                </div>
              </div>
            </div>
          )}
          <div>
            {filterItem === "All" && (
              <div className="flex items-center pr-1 pb-2" key={index}>
                <img src="uiAsset/GiftBoxIcon.png" className="h-14 " />
                <div className="text-sm self-start mt-1">
                  <div className="text-blue-800 font-bold">
                    {item?.data?.name || ""}
                  </div>
                  <div className="text-gray-500 pr-1">
                    {" "}
                    Shinhan Bank gift you an eVoucher Got It valued{" "}
                    {item?.data?.price || ""}
                    VND through "How to win customer smiles"
                  </div>
                  <div className="text-gray-500">
                    Please click on the{" "}
                    <span
                      className="text-blue-500"
                      onClick={() => openExternalLink(item?.data?.link || "")}
                    >
                      link{" "}
                    </span>
                    to use
                  </div>
                  <div className="text-blue-700 text-[0.625rem] mt-1">
                    Received:{" "}
                    {dayjs(item?.createdAt).add(-1, "day").format("DD/MM/YYYY")}{" "}
                    | Expiry date: {item?.data?.exp || ""}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
