import { Assets } from "./util/assets";

export const getAssets = (gl: WebGLRenderingContext) => {
  return new Assets(gl)
    .texture("circle", "./tex/circle.png")
    .texture("Bank Image", "./uiAsset/topicText/BankImage.png")
    .texture(
      "Communication skill",
      "./uiAsset/topicText/CommunicationSkill.png"
    )
    .texture("Customer care", "./uiAsset/topicText/CustomerCare.png")
    .texture(
      "Customer satisfaction",
      "./uiAsset/topicText/CustomerSatisfaction.png"
    )
    .texture("Empathy", "./uiAsset/topicText/Empathy.png")
    .texture("Greeting customer", "./uiAsset/topicText/Greetingcustomer.png")
    .texture("Listening skill", "./uiAsset/topicText/ListeningSkill.png")
    .texture("Provide infomation", "./uiAsset/topicText/ProvideInfomation.png")
    .texture("Staff Appearance", "./uiAsset/topicText/StaffAppearance.png")
    .texture(
      "Time management skills",
      "./uiAsset/topicText/TimeManagementSkills.png"
    )
    .texture("Wow Service", "./uiAsset/topicText/WowService.png")

    .font("normal", "./fonts/shinhan.fnt", true)
    .font("shinhanBold", "./fonts/OneShinhanBold.fnt", true);
};
