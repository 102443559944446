/* eslint-disable @typescript-eslint/no-explicit-any */
import { action, Action, createStore } from "easy-peasy";
import { GameConfigs } from "./types";
import gamefoxSDK from "./util/gameFoxSDK";

export interface StoreModel {
  layoutContainerStyle: { [key: string]: any };
  setLayoutContainerStyle: Action<StoreModel, { [key: string]: any }>;
  layoutMainStyle: { [key: string]: any };
  setLayoutMainStyle: Action<StoreModel, { [key: string]: any }>;
  noticeModal: {
    visible: boolean;
    action: string;
    buttonLabel?: string;
    notice: string;
  };
  setNoticeModal: Action<
    StoreModel,
    { visible: boolean; action: string; buttonLabel?: string; notice: string }
  >;

  tabletMode: boolean;
  setTabletMode: Action<StoreModel, boolean>;

  instructMode: boolean;
  setInstructMode: Action<StoreModel, boolean>;

  sorryPopup: boolean;
  setSorryPopup: Action<StoreModel, boolean>;

  user: null | Awaited<ReturnType<typeof gamefoxSDK.auth>>;
  setUser: Action<
    StoreModel,
    Awaited<ReturnType<typeof gamefoxSDK.auth>> | null
  >;
  inventory: null | Awaited<ReturnType<typeof gamefoxSDK.getInventory>>;
  setInventory: Action<
    StoreModel,
    Awaited<ReturnType<typeof gamefoxSDK.getInventory>>
  >;
  gameConfigs: GameConfigs;
  setGameConfig: Action<StoreModel, GameConfigs>;
  setNotifications: Action<
    StoreModel,
    Awaited<ReturnType<typeof gamefoxSDK.getNotifications>>
  >;
  notifications: [] | Awaited<ReturnType<typeof gamefoxSDK.getNotifications>>;
  gotHint: boolean;
  setGotHint: Action<StoreModel, boolean>;
}

const store = createStore<StoreModel>({
  layoutContainerStyle: {},
  layoutMainStyle: {},
  setLayoutContainerStyle: action((state, style) => {
    state.layoutContainerStyle = style;
  }),
  setLayoutMainStyle: action((state, style) => {
    state.layoutMainStyle = style;
  }),
  noticeModal: {
    visible: false,
    action: "",
    notice: "",
  },
  setNoticeModal: action((state, payload) => {
    state.noticeModal = payload;
  }),
  user: null,
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  tabletMode: false,
  setTabletMode: action((state, payload) => {
    state.tabletMode = payload;
  }),
  instructMode: false,
  setInstructMode: action((state, payload) => {
    state.instructMode = payload;
  }),
  inventory: null,
  setInventory: action((state, payload) => {
    state.inventory = payload;
  }),
  gameConfigs: {
    totalScreenPlayed: 0,
    score: 0,
    scorePerAnswer: 10,
    timeRemaining: 120,
    topic: undefined,
    suggestItem: 0,
    skipItem: 0,
    ignoreSuggestWord: [],
    correctAllAnswersPos: [],
    answersSuggested: [],
    scoreInRound: 0,
  },
  setGameConfig: action((state, payload) => {
    state.gameConfigs = payload;
  }),
  setNotifications: action((state, payload) => {
    state.notifications = payload;
  }),
  notifications: [],
  gotHint: false,
  setGotHint: action((state, payload) => {
    state.gotHint = payload;
  }),
  sorryPopup: false,
  setSorryPopup: action((state, payload) => {
    state.sorryPopup = payload;
  }),
});

export default store;
