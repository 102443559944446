import { EventEmitter } from "events";
import TypedEmitter from "typed-emitter";
import { Question } from "../types";

export type MessageEvents = {
  newQuestions: (questions: Question[]) => void;
  nextLevel: () => void;
  answer: (index: number, answer: string) => void;
  setTimeLeft: (time: number) => void;
  correctAnswer: (point: number) => void;
  suggest: () => void;
  onGameEnded: () => void;
  onInstructMode: () => void;
  offInstructMode: () => void;
  noticeGameStart: () => void;
  noticeTimesUp: () => void;
  pauseGame: (pause: boolean) => void;
  renewQuestions: () => void;
  haveDupliAnswer: (countQuestion: number) => void;
  showOverlay: (x: number, y: number) => void;
  showTest: (x: number, y: number) => void;
  loadDone: () => void;
};

export const emitter = new EventEmitter() as TypedEmitter<MessageEvents>;
