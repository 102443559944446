import { motion } from "framer-motion";
import { useStoreState } from "../../game/util/hook";
import Modal from "../Modal";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  zIndex?: number;
}

export const NoticeEndgamePopup = ({
  isOpen,
  closeModal,
  zIndex,
}: ModalProps) => {
  const gameConfigs = useStoreState((store) => store.gameConfigs);

  return (
    <Modal
      visible={isOpen}
      onDismiss={closeModal}
      dismissOnBackdropClick={false}
      zIndex={zIndex}
      backdropColor="rgba(0, 0, 0, 0.8)"
    >
      <motion.div
        animate={{
          scale: isOpen ? 1 : 0,
        }}
        initial={{
          scale: 0,
        }}
        transition={{
          duration: 0.5,
          ease: [0.5, 2.25, 1, 0.75],
        }}
        className="flex flex-1 flex-col max-w-xs justify-center items-center"
      >
        <img src="uiAsset/NoticeEndgame.png" onClick={closeModal} />
        <div className="flex absolute">
          <div className="text-[#00008B] font-bold ml-16 flex flex-col">
            <div className="-ml-2 p-1">You got</div>
            <div className="flex justify-center items-center">
              {gameConfigs.scoreInRound}
              <span>
                <img src="uiAsset/PointIcon.png" className="h-8" />
              </span>
            </div>
          </div>
        </div>
        <div className="absolute mt-60 border-orange-400 border-2 p-1 pl-4 pr-4 rounded-full text-orange-400 font-bold">
          Time left: {Math.floor(gameConfigs.timeRemaining)}
        </div>
      </motion.div>
    </Modal>
  );
};
