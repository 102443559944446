import { OrthoCamera, Vector2, Vector3 } from "gdxts";
import { toNumber, words } from "lodash";
import { SCREEN_HEIGHT, SCREEN_WIDTH, dimensionInfo } from "../Constants";
import { GameManager } from "../initGame";
import { emitter } from "../util/emitter";
import gamefoxSDK from "../util/gameFoxSDK";
import { SoundFxId, soundUtils } from "../util/soundUtil";

function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const registerGameStateSystem = (manager: GameManager) => {
  manager.addSystem(() => {
    const viewport = manager.context.viewport;
    const camera = manager.context.camera;

    const gameConfigs = manager.context.gameConfigs;
    const currentGame = manager.context.currentGame;

    const sendPosWordToScreen = (posWord: Vector2, indexOfAnswer: number) => {
      const tmpVector3 = new Vector3();
      const getScreenVec2 = (
        camera: OrthoCamera,
        worldCoords: Vector2,
        screenCoords: Vector2
      ) => {
        tmpVector3.set(worldCoords.x, worldCoords.y, 0);

        camera.worldToScreen(
          tmpVector3,
          camera.screenWidth,
          camera.screenHeight
        );
        screenCoords
          .set(tmpVector3.x, tmpVector3.y)
          .scale(1 / viewport.getViewportInfo().pixelRatio);
      };

      const overlayPos = new Vector2(posWord.x, SCREEN_HEIGHT - posWord.y);
      const tmp = new Vector2();

      tmp.setVector(overlayPos);
      getScreenVec2(camera, tmp, overlayPos);

      emitter.emit("showOverlay", overlayPos.x, overlayPos.y);

      return overlayPos;
    };

    const cellPos = new Vector2();
    const getCellPos = (x: number, y: number) => {
      cellPos.set(
        x *
          (dimensionInfo.dimensions.GRID_SIZE +
            dimensionInfo.dimensions.BORDER) +
          dimensionInfo.dimensions.PADDING +
          dimensionInfo.dimensions.BORDER +
          10,
        y *
          (dimensionInfo.dimensions.GRID_SIZE +
            dimensionInfo.dimensions.BORDER) +
          dimensionInfo.dimensions.START_Y +
          dimensionInfo.dimensions.BORDER
      );
      return cellPos;
    };

    const calPosWordSuggest = (x: number, y: number) => {
      const pos = getCellPos(x, y);
      pos.add(
        dimensionInfo.dimensions.GRID_SIZE / 2,
        dimensionInfo.dimensions.GRID_SIZE / 2
      );
      // batch.setColor(Color.GREEN);
      // batch.draw(
      //   circle,
      //   pos.x + dimensionInfo.dimensions.GRID_SIZE / 2 - CIRCLE_SIZE / 2,
      //   pos.y + dimensionInfo.dimensions.GRID_SIZE / 2 - CIRCLE_SIZE / 2,
      //   CIRCLE_SIZE,
      //   CIRCLE_SIZE
      // );
      // batch.setColor(Color.WHITE);
      return pos;
    };

    const createSuggest = () => {
      if (
        gameConfigs.ignoreSuggestWord.length <
        gameConfigs.correctAllAnswersPos.length
      ) {
        let randomIndex = randomIntFromInterval(1, 3);

        // eslint-disable-next-line no-constant-condition
        while (true) {
          if (gameConfigs.ignoreSuggestWord.indexOf(randomIndex) === -1) {
            gameConfigs.answersSuggested.push(
              gameConfigs.correctAllAnswersPos[randomIndex - 1].col,
              gameConfigs.correctAllAnswersPos[randomIndex - 1].row
            );
            const posWord = calPosWordSuggest(
              gameConfigs.correctAllAnswersPos[randomIndex - 1].col,
              gameConfigs.correctAllAnswersPos[randomIndex - 1].row
            );
            sendPosWordToScreen(posWord, randomIndex - 1);

            gameConfigs.ignoreSuggestWord.push(randomIndex);
            break;
          }
          randomIndex = randomIntFromInterval(1, 3);
        }
      }
    };
    emitter.on("suggest", createSuggest);

    const handleGameEnded = async () => {
      const user = await gamefoxSDK.auth();
      if (user && user.progressData && user.progressData.wordSearchScore) {
        gameConfigs.score = user.progressData.wordSearchScore;
      }
    };

    emitter.on("onGameEnded", async () => {
      await handleGameEnded();
      currentGame.ended = true;
    });
    const handleAnswer = (index: number, answer: string) => {
      for (const answerPos in gameConfigs.correctAllAnswersPos) {
        if (
          answer === gameConfigs.correctAllAnswersPos[answerPos].wordToPlace
        ) {
          index = toNumber(answerPos) + 1;
          if (gameConfigs.ignoreSuggestWord.indexOf(index) === -1)
            gameConfigs.ignoreSuggestWord.push(index);
          break;
        }
      }
    };
    emitter.on("answer", handleAnswer);

    return {
      process() {
        if (
          gameConfigs.timeRemaining <= 0 &&
          manager.context.currentGame.ended === false
        ) {
          emitter.emit("noticeTimesUp");
          manager.context.currentGame.ended = true;
          soundUtils.play(SoundFxId.COUNT_TIMER_LEFT);
        }
      },
      dispose() {
        emitter.off("suggest", createSuggest);
        emitter.off("onGameEnded", handleGameEnded);
        emitter.off("answer", handleAnswer);
      },
    };
  });
};
