import { createTypedHooks } from "easy-peasy";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import gamefoxSDK from "./gameFoxSDK";
import debounce from "lodash.debounce";
import { StoreModel } from "../store";

const typedHooks = createTypedHooks<StoreModel>();

const useStoreActions = typedHooks.useStoreActions;
const useStoreState = typedHooks.useStoreState;
export const useTouchOutside = (): [
  MutableRefObject<HTMLDivElement | undefined>,
  string,
  (key: string) => void
] => {
  const nodeRef = useRef<HTMLDivElement>();
  const [currentRefKey, setCurrentRefKey] = useState("");

  useEffect(() => {
    const clickEvent = (() => {
      if ("ontouchstart" in document.documentElement === true) {
        return "touchstart";
      } else {
        return "mousedown";
      }
    })();

    const handleTouch = debounce((event: TouchEvent | MouseEvent) => {
      if (!nodeRef.current) {
        setCurrentRefKey("");
        return;
      }
      const target = event.target as any;
      if (target === nodeRef.current || nodeRef.current?.contains(target)) {
        return;
      } else {
        setCurrentRefKey("");
        nodeRef.current = undefined;
      }
    }, 0);

    window?.document?.addEventListener(clickEvent, handleTouch);
    return () => {
      window?.document?.removeEventListener(clickEvent, handleTouch);
    };
  }, []);

  return [nodeRef, currentRefKey, setCurrentRefKey];
};
const useRefreshInventory = () => {
  const setInventory = useStoreActions((actions) => actions.setInventory);

  const fetchInventory = useCallback(async () => {
    const sdkParams = gamefoxSDK.getParams();
    const inventory = await gamefoxSDK.getInventory(
      sdkParams.campaignId,
      "CAMPAIGN"
    );

    setInventory(inventory);
  }, [setInventory]);

  return fetchInventory;
};

const useRefreshNotifications = () => {
  const setNoti = useStoreActions((actions) => actions.setNotifications);
  const fetchNoti = useCallback(async () => {
    const getnoti = await gamefoxSDK.getNotifications();

    setNoti(getnoti);
  }, [setNoti]);
  return fetchNoti;
};

const useRefreshUser = () => {
  const setUser = useStoreActions((actions) => actions.setUser);
  const fetchUser = useCallback(async () => {
    const user = await gamefoxSDK.auth();
    setUser(user);
  }, [setUser]);
  return fetchUser;
};

export { useRefreshInventory };
export { useRefreshUser };
export { useRefreshNotifications };

export { useStoreActions, useStoreState };
