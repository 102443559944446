import Modal from "../Modal";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  zIndex?: number;
}

export const TimesUpPopup = ({ isOpen, closeModal, zIndex }: ModalProps) => {
  return (
    <Modal
      visible={isOpen}
      onDismiss={closeModal}
      dismissOnBackdropClick={false}
      zIndex={zIndex}
      backdropColor="rgba(0, 0, 0, 0.8)"
    >
      <div className="flex w-[75%] max-w-xs ">
        <img src="uiAsset/TimesUpPopup.png" onClick={closeModal} />
      </div>
    </Modal>
  );
};
