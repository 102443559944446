import { motion } from "framer-motion";
import Modal from "../Modal";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  zIndex?: number;
}

export const SuggestPopup = ({ isOpen, closeModal, zIndex }: ModalProps) => {
  return (
    <Modal
      visible={isOpen}
      onDismiss={closeModal}
      dismissOnBackdropClick={false}
      zIndex={zIndex}
      backdropColor="rgba(0, 0, 0, 0.8)"
    >
      <motion.div
        animate={{
          scale: isOpen ? 1 : 0,
        }}
        initial={{
          scale: 0,
        }}
        transition={{
          duration: 0.5,
          ease: [0.5, 2.25, 1, 0.75],
        }}
        className="flex w-[80%] max-w-xs"
      >
        <img src="uiAsset/SuggestGIftPopup.png" onClick={closeModal} />
      </motion.div>
    </Modal>
  );
};
