/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AssetManager,
  BitmapFont,
  Texture,
  TextureAtlas,
  TextureOptions,
} from "gdxts";
export class Assets<Atlases, Textures, Fonts> {
  private assetManager: AssetManager;
  constructor(gl: WebGLRenderingContext) {
    this.assetManager = new AssetManager(gl);
  }
  atlas<K extends string>(
    name: K,
    url: string,
    useMipMaps = false
  ): Assets<Atlases & { [key in K]: string }, Textures, Fonts> {
    this.assetManager.loadAtlas(url, name, useMipMaps);
    return this as any;
  }
  texture<K extends string>(
    name: K,
    url: string,
    options?: Partial<TextureOptions>
  ): Assets<Atlases, Textures & { [key in K]: string }, Fonts> {
    this.assetManager.loadTexture(url, name, options);
    return this as any;
  }
  font<K extends string>(
    name: K,
    url: string,
    flip = false
  ): Assets<Atlases, Textures, Fonts & { [key in K]: string }> {
    this.assetManager.loadFont(url, name, flip);
    return this as any;
  }
  async finishLoading() {
    await this.assetManager.finishLoading();
  }
  getAtlas<K extends keyof Atlases>(name: K): TextureAtlas | undefined {
    if (typeof name !== "string") {
      throw new Error("Atlas name must be a string");
    }
    return this.assetManager.getAtlas(name);
  }
  getTexture<K extends keyof Textures>(name: K): Texture | undefined {
    if (typeof name !== "string") {
      throw new Error("Texture name must be a string");
    }
    return this.assetManager.getTexture(name);
  }
  getFont<K extends keyof Fonts>(name: K): BitmapFont | undefined {
    if (typeof name !== "string") {
      throw new Error("Font name must be a string");
    }
    return this.assetManager.getFont(name);
  }
  dispose() {
    this.assetManager.disposeAll();
  }
  getDone(): number {
    return this.assetManager.getDone();
  }
  getTotal(): number {
    return this.assetManager.getTotal();
  }
}

const ASSETS = {
  HOMESCREEN_BG: "uiAsset/MainScreen.png",
  GO_BACK_BUTTON: "uiAsset/GoBackButton.png",
  MAINGAME_BG: "uiAsset/BackgroundGamePlaying.png",
  QUIT_BUTTON: "uiAsset/QuitButton.png",
  SOUND_ON_BUTTON: "uiAsset/SoundOnButton.png",
  SOUND_OFF_BUTTON: "uiAsset/MutedSoundIcon.png",
  GAME_NAME: "uiAsset/GameName.png",
  BANK_NAME: "uiAsset/BankName.png",
  HANDBOOK_BUTTON: "uiAsset/HandBookButton.png",
  PROGRESS_PERCENT_BUTTON: "uiAsset/ProgressPercentButton.png",
  SHINHAN_CHARACTER: "uiAsset/ShinhanCharacter.png",
  PLAY_NOW_BUTTON: "uiAsset/PlayNowButton.png",
  PRIZES_BUTTON: "uiAsset/PrizesButton.png",
  HOW_TO_PLAY_BUTTON: "uiAsset/HowToPlayButton.png",
  LEADERBOARD_BUTTON: "uiAsset/LeaderboardButton.png",
  BORDER_GAME_PLAYING: "uiAsset/BorderGamePlaying.png",
  QUESTIONS_BOARD: "uiAsset/QuestionsBoard.png",
  BANNER_LEADERBOARD: "uiAsset/bannerLeaderboard.png",
  BOARD_OF_LEADERBOARD: "uiAsset/BoardOfLeaderboard.png",
  NAME_TAG_LEVEL_1: "uiAsset/NametagTop1.png",
  NAME_TAG_LEVEL_2: "uiAsset/NametagTop2.png",
  NAME_TAG_LEVEL_N: "uiAsset/NametagTopN.png",
  POINT_SCORE: "uiAsset/PointIcon.png",
  FLAG_ICON: "uiAsset/FlagLevelIcon.png",
};

export const MINI_VOUCHER_IMAGES = {
  // [VoucherType.VOUCHER_100K]: ASSETS.VOUCHER_100K,
  // [VoucherType.VOUCHER_200K]: ASSETS.VOUCHER_200K,
  // [VoucherType.VOUCHER_300K]: ASSETS.VOUCHER_300K,
  // [VoucherType.VOUCHER_400K]: ASSETS.VOUCHER_400K,
  // [VoucherType.VOUCHER_500K]: ASSETS.VOUCHER_500K,
  // [VoucherType.VOUCHER_2M]: ASSETS.VOUCHER_2M,
  // [VoucherType.VOUCHER_3M]: ASSETS.VOUCHER_3M,
};

export const cacheAssets = () =>
  new Promise((resolve) => {
    const imageToLoad = Object.keys(ASSETS).length;
    let imageLoaded = 0;
    if (imageToLoad <= 0) {
      resolve(null);
    }
    Object.keys(ASSETS).forEach((assetKey) => {
      loadImage(ASSETS[assetKey])
        .then(() => {
          imageLoaded++;
          if (imageLoaded >= imageToLoad) {
            resolve(null);
          }
        })
        .catch((e: any) => {
          if (imageLoaded >= imageToLoad) {
            resolve(null);
          }
        });
    });
  });

export const loadImage = (src: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      resolve(null);
    };
    image.onerror = () => {
      reject(null);
    };
  });

export const sleep = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, ms);
  });

export { ASSETS };
