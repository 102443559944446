import Modal from "../Modal";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  zIndex?: number;
}

export const PreLeaderboardPopup = ({
  isOpen,
  closeModal,
  zIndex,
}: ModalProps) => {
  const dataWeek0 = [
    {
      username: "NGUYEN THI XUAN MAI",
      score: 470850,
    },
    {
      username: "NGUYEN THU TRANG",
      score: 346300,
    },
    {
      username: "NGO DIEM NGAN TRANG",
      score: 261780,
    },
    {
      username: "VU THI NGOC HUYEN",
      score: 257000,
    },
    {
      username: "NGUYEN THI KIM HOA",
      score: 245720,
    },
    {
      username: "TRUONG THUY AN",
      score: 239350,
    },
    {
      username: "NGUYEN TAN PHAT",
      score: 238650,
    },
    {
      username: "TRAN THI DIEM MY",
      score: 238640,
    },
    {
      username: "NGUYEN THI BICH TIEN",
      score: 238150,
    },
    {
      username: "NGUYEN THI MINH TRANG",
      score: 235390,
    },
  ];

  const dataWeek1 = [
    {
      username: "NGUYEN THU TRANG",
      score: 244000,
    },
    {
      username: "TRUONG THUY AN",
      score: 226630,
    },
    {
      username: "PHAM THANH HUYEN",
      score: 221540,
    },
    {
      username: "NGUYEN THI MINH TRANG",
      score: 186310,
    },
    {
      username: "NGUYEN THI XUAN MAI",
      score: 185730,
    },
    {
      username: "HA THUY VAN",
      score: 185190,
    },
    {
      username: "NGUYEN TAN PHAT",
      score: 180990,
    },
    {
      username: "DANG THI XUAN THU",
      score: 177060,
    },
    {
      username: "VAN NU BAO KHANH",
      score: 176780,
    },
    {
      username: "PHAM NGHI CAT KHANH",
      score: 175900,
    },
  ];

  const dataWeek2 = [
    {
      username: "NGUYEN THI XUAN MAI",
      score: 488810,
    },
    {
      username: "PHAM NGHI CAT KHANH",
      score: 428210,
    },
    {
      username: "NGUYEN THU TRANG",
      score: 302910,
    },
    {
      username: "NGUYEN THI KIM HOA",
      score: 294860,
    },
    {
      username: "TRUONG THUY AN",
      score: 294300,
    },
    {
      username: "PHAM THANH HUYEN",
      score: 275570,
    },
    {
      username: "TRAN THI DIEM MY",
      score: 239170,
    },
    {
      username: "TRUONG THI THANH TUYEN",
      score: 211550,
    },
    {
      username: "NGUYEN THI MINH TRANG",
      score: 209700,
    },
    {
      username: "VAN NU BAO KHANH",
      score: 206700,
    },
  ];

  const dataWeek3 = [
    {
      username: "NGUYEN THU TRANG",
      score: 341010,
    },
    {
      username: "PHAM NGHI CAT KHANH",
      score: 176770,
    },
    {
      username: "PHAM THANH HUYEN",
      score: 175760,
    },
    {
      username: "NGUYEN THI XUAN MAI",
      score: 174450,
    },
    {
      username: "NGUYEN THI BICH TIEN",
      score: 171750,
    },
    {
      username: "NGO DIEM NGAN TRANG",
      score: 150200,
    },
    {
      username: "TRUONG THUY AN",
      score: 140090,
    },
    {
      username: "DANG THI XUAN THU",
      score: 132400,
    },
    {
      username: "VO THI MY TRINH",
      score: 132400,
    },
    {
      username: "VAN NU BAO KHANH",
      score: 131100,
    },
  ];

  //NGUYEN THU TRANG 661130
  // NGUYEN THI XUAN MAI 476490
  // PHAM THANH HUYEN 291010
  // TRUONG THUY AN 285760
  // TRAN THI DIEM MY 249630
  // VU THI NGOC HUYEN 207060
  // NGO DIEM NGAN TRANG 195750
  // NGUYEN THI MINH TRANG 181450
  // NGUYEN THI BICH TIEN 151170
  // NGUYEN THI KIM HOA 105700

  const dataEndGame = [
    {
      username: "NGUYEN THU TRANG",
      score: 661130,
    },
    {
      username: "NGUYEN THI XUAN MAI",
      score: 476490,
    },
    {
      username: "PHAM THANH HUYEN",
      score: 291010,
    },
    {
      username: "TRUONG THUY AN",
      score: 285760,
    },
    {
      username: "TRAN THI DIEM MY",
      score: 249630,
    },
    {
      username: "VU THI NGOC HUYEN",
      score: 207060,
    },
    {
      username: "NGO DIEM NGAN TRANG",
      score: 195750,
    },
    {
      username: "NGUYEN THI MINH TRANG",
      score: 181450,
    },
    {
      username: "NGUYEN THI BICH TIEN",
      score: 151170,
    },
    {
      username: "NGUYEN THI KIM HOA",
      score: 105700,
    },
  ];
  return (
    <Modal
      visible={isOpen}
      onDismiss={closeModal}
      dismissOnBackdropClick={false}
      zIndex={zIndex}
      backdropColor="rgba(0, 0, 0, 0.8)"
    >
      <div className="flex w-full flex-col items-center ml-1 max-w-xs">
        <img src="uiAsset/PreLeaderboardPopup.png" onClick={closeModal} />
        <div className="absolute flex flex-col w-[65%] max-w-xs items-center -ml-4 mt-[9rem] gap-1 h-[17.5rem] overflow-scroll">
          <p className="text-[#2B74F6] font-[SHBold] text-sm -mt-1">
            Week: 29/01/2024 - 04/02/2024
          </p>

          {dataEndGame.map((user, index) => (
            <div
              key={index}
              className="flex justify-between w-full text-xs items-center"
            >
              <img
                src="uiAsset/PreLeaderboardNameTag.png"
                className="relative"
              />
              <div className="absolute flex items-center w-full pl-2 pr-2 text-[#002DAA] font-medium">
                <div className="w-8">{index + 1}. </div>
                <div className="w-[65%]">{user.username}</div>
                <div className="">{user.score}</div>
                <img src="uiAsset/PointIcon.png" className="w-5" />
              </div>
            </div>
          ))}

          <p className="text-[#2B74F6] font-[SHBold] text-sm -mt-1">
            Week: 22/01/2024 - 28/01/2024
          </p>

          {dataWeek0.map((user, index) => (
            <div
              key={index}
              className="flex justify-between w-full text-xs items-center"
            >
              <img
                src="uiAsset/PreLeaderboardNameTag.png"
                className="relative"
              />
              <div className="absolute flex items-center w-full pl-2 pr-2 text-[#002DAA] font-medium">
                <div className="w-8">{index + 1}. </div>
                <div className="w-[65%]">{user.username}</div>
                <div className="">{user.score}</div>
                <img src="uiAsset/PointIcon.png" className="w-5" />
              </div>
            </div>
          ))}

          <p className="text-[#2B74F6] font-[SHBold] text-sm -mt-1">
            Week: 15/01/2024 - 21/01/2024
          </p>

          {dataWeek1.map((user, index) => (
            <div
              key={index}
              className="flex justify-between w-full text-xs items-center"
            >
              <img
                src="uiAsset/PreLeaderboardNameTag.png"
                className="relative"
              />
              <div className="absolute flex items-center w-full pl-2 pr-2 text-[#002DAA] font-medium">
                <div className="w-8">{index + 1}. </div>
                <div className="w-[65%]">{user.username}</div>
                <div className="">{user.score}</div>
                <img src="uiAsset/PointIcon.png" className="w-5" />
              </div>
            </div>
          ))}

          <p className="text-[#2B74F6] font-[SHBold] text-sm mt-5">
            Week: 08/01/2024 - 14/01/2024
          </p>

          {dataWeek2.map((user, index) => (
            <div
              key={index}
              className="flex justify-between w-full text-xs items-center"
            >
              <img
                src="uiAsset/PreLeaderboardNameTag.png"
                className="relative"
              />
              <div className="absolute flex items-center w-full pl-2 pr-2 text-[#002DAA] font-medium">
                <div className="w-8">{index + 1}. </div>
                <div className="w-[65%]">{user.username}</div>
                <div className="">{user.score}</div>
                <img src="uiAsset/PointIcon.png" className="w-5" />
              </div>
            </div>
          ))}

          <p className="text-[#2B74F6] font-[SHBold] text-sm mt-5">
            Week: 02/01/2024 - 07/01/2024
          </p>

          {dataWeek3.map((user, index) => (
            <div
              key={index}
              className="flex justify-between w-full text-xs items-center"
            >
              <img
                src="uiAsset/PreLeaderboardNameTag.png"
                className="relative"
              />
              <div className="absolute flex items-center w-full pl-2 pr-2 text-[#002DAA] font-medium">
                <div className="w-8">{index + 1}. </div>
                <div className="w-[65%]">{user.username}</div>
                <div className="">{user.score}</div>
                <img src="uiAsset/PointIcon.png" className="w-5" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};
