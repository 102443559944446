import {
  MultiTextureBatch,
  ViewportInputHandler,
  createGameLoop,
  createViewport,
} from "gdxts";
import { getDummyGameState } from "../data/dummyData";
import { getAssets } from "./Assets";
import {
  SCREEN_HEIGHT,
  SCREEN_WIDTH,
  calculateDimensions,
  dimensionInfo,
} from "./Constants";
import { BorderGameRenderSystem } from "./system/BorderGameRenderSystem";
import { registerGameStateSystem } from "./system/GameStateSystem";
import { registerGridRenderSystem } from "./system/GridRenderSystem";
import { registerInputSystem } from "./system/InputSystem";
import { InstructModeSystem } from "./system/InstructModeSystem";
import { GameConfigs, Question } from "./types";
import { emitter } from "./util/emitter";
import { Manager } from "./util/sysman";
import { generateWS } from "./util/word-search-gen";
import { getPosCorrectWord } from "./util/word-search/generate";

export const initGame = async (
  canvas: HTMLCanvasElement,
  gameConfigs: GameConfigs
) => {
  dimensionInfo.dimensions = calculateDimensions();
  const viewport = createViewport(canvas, SCREEN_WIDTH, SCREEN_HEIGHT, {
    crop: false,
  });
  const gl = viewport.getContext();
  const camera = viewport.getCamera();
  camera.setYDown(true);

  const batch = new MultiTextureBatch(gl);
  batch.setYDown(true);

  const inputHandler = new ViewportInputHandler(viewport);

  const assets = getAssets(gl);
  await assets.finishLoading();

  const gameState = getDummyGameState();
  const currentGame = {
    instructMode: false,
    started: false,
    paused: false,
    ended: false,
    soundEnd: false,
  };

  const manager = new Manager()
    .register("state", gameState)
    .register("viewport", viewport)
    .register("gl", gl)
    .register("camera", camera)
    .register("batch", batch)
    .register("inputHandler", inputHandler)
    .register("currentGame", currentGame)
    .register("gameState", gameState)
    .register("gameConfigs", gameConfigs)
    .register("assets", assets);

  registerInputSystem(manager);
  registerGridRenderSystem(manager);
  registerGameStateSystem(manager);
  InstructModeSystem(manager);
  BorderGameRenderSystem(manager);
  const handleNewRound = async (questions: Question[]) => {
    currentGame.started = true;
    currentGame.paused = false;
    currentGame.ended = false;
    currentGame.soundEnd = false;

    const words = questions.map((q) => q.answer);
    const [grid, placedWord] = generateWS(
      words,
      dimensionInfo.dimensions.COL,
      dimensionInfo.dimensions.ROW,
      false,
      false
    );

    console.log(placedWord);

    gameState.originalWords = words;
    gameState.grid = grid;
    gameState.words = words;
    gameState.foundLines = [];
    gameConfigs.timeRemaining = 120;
    gameConfigs.answersSuggested = [];
    gameConfigs.ignoreSuggestWord = [];

    setTimeout(() => {
      gameConfigs.correctAllAnswersPos = getPosCorrectWord();
    }, 1000);
  };

  emitter.on("newQuestions", handleNewRound);
  const handleOnInstructMode = () => {
    currentGame.instructMode = true;
  };
  emitter.on("onInstructMode", handleOnInstructMode);
  emitter.on("nextLevel", () => {
    gameConfigs.totalScreenPlayed += 1;
  });
  emitter.on("pauseGame", (pause: boolean) => {
    currentGame.paused = pause;
  });
  emitter.emit("loadDone");

  const sendTime = setInterval(() => {
    emitter.emit("setTimeLeft", gameConfigs.timeRemaining);
  }, 1000);

  gl.clearColor(0, 0, 0, 0);
  const loop = createGameLoop((delta: number) => {
    gl.clear(gl.COLOR_BUFFER_BIT);
    batch.setProjection(camera.combined);
    if (currentGame.started) {
      batch.begin();

      if (
        !currentGame.ended &&
        currentGame.started &&
        !currentGame.paused &&
        !currentGame.instructMode
      ) {
        gameConfigs.timeRemaining -= delta;
      }
      if (currentGame.instructMode) {
        gameConfigs.timeRemaining = 120;
      }
      manager.process(delta);
      batch.end();
    }
  });

  return {
    manager,
    dispose() {
      loop.stop();
      batch.dispose();
      inputHandler.cleanup();
      assets.dispose();
      manager.dispose();
      emitter.off("newQuestions", handleNewRound);
      emitter.off("onInstructMode", handleOnInstructMode);
      emitter.off("nextLevel", () => {});
      emitter.off("pauseGame", () => {});
      clearInterval(sendTime);
    },
  };
};

export type GameManager = Awaited<ReturnType<typeof initGame>>["manager"];
