import { Direction, getWordRowCol, randomDirection } from "./direction";
import type { Grid, GridGame, GridOptions } from "./grid";
import {
  cloneGrid,
  createGrid,
  maxColIndex,
  maxRowIndex,
  minColIndex,
  minRowIndex,
} from "./grid";
import { randomChar, randomIntInRange } from "./random";
import { cleanWord, sortWordList } from "./word";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const posCorrectWords: any[] = [];

export const getPosCorrectWord = () => {
  return posCorrectWords;
};

type PlaceOptions = {
  allowBackwards?: boolean;
  allowDiagonals?: boolean;
  maxAttempts?: number;
};

type GenerateOptions = {
  words: string[];
  fillBlanks?: boolean;
} & GridOptions &
  PlaceOptions;

const placeWordRandom = (
  grid: Grid,
  word: string,
  { allowBackwards = false, maxAttempts = 99999 }: PlaceOptions
): Grid => {
  const placeWordInner = () => {
    const direction = randomDirection();
    const clonedGrid = cloneGrid(grid);
    const wordToPlace = word;

    const row = randomIntInRange(minRowIndex(), maxRowIndex(word, clonedGrid));
    const col = randomIntInRange(minColIndex(), maxColIndex(word, clonedGrid));

    const canPlaceWord = () => {
      for (let i = 0; i < wordToPlace.length; i++) {
        const { row: r, col: c } = getWordRowCol(direction, row, col, i);
        const cell = clonedGrid[r]![c];

        if (cell !== undefined) {
          return false;
        }
      }

      return true;
    };

    if (canPlaceWord()) {
      let pushed = false;
      for (let i = 0; i < wordToPlace.length; i++) {
        const { row: r, col: c } = getWordRowCol(direction, row, col, i);

        if (pushed === false) {
          pushed = true;
          posCorrectWords.push({ row, col, direction, wordToPlace });
        }
        clonedGrid[r]![c] = wordToPlace[i];
      }
      return clonedGrid;
    }
    return undefined;
  };

  let newGrid: undefined | Grid;
  let attempt = 1;
  do {
    newGrid = placeWordInner();
  } while (newGrid === undefined && attempt++ < maxAttempts);

  if (newGrid !== undefined) {
    return newGrid;
  }

  throw new Error(
    `Could not place word ${word} in grid after ${maxAttempts} attempts`
  );
};

const generateWordSearch = ({
  words,
  size,
  fillBlanks = true,
  ...rest
}: GenerateOptions): GridGame => {
  posCorrectWords.length = 0;
  const sortedWords = sortWordList(words.map(cleanWord));

  const placedWords: string[] = [];
  let grid = createGrid({ size });
  sortedWords.forEach((w) => {
    try {
      grid = placeWordRandom(grid, w, rest);
      placedWords.push(w);
    } catch (e) {
      console.log(e);
    }
  });

  if (fillBlanks) {
    grid = grid.map((row) => row.map((c) => c ?? randomChar()));
  }

  return {
    grid,
    placedWords,
  };
};

export default generateWordSearch;
