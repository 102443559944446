import Modal from "../Modal";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  zIndex?: number;
  handleCancelSkip: () => void;
}

export const ConfirmSkipLevelPopup = ({
  isOpen,
  closeModal,
  zIndex,
  handleCancelSkip,
}: ModalProps) => {
  return (
    <Modal
      visible={isOpen}
      onDismiss={closeModal}
      dismissOnBackdropClick={false}
      zIndex={zIndex}
      backdropColor="rgba(0, 0, 0, 0.8)"
    >
      <div className="flex flex-col w-full h-full justify-center items-center mt-12">
        <div className="flex w-[75%] max-w-xs">
          <button onClick={closeModal}>
            <img src="uiAsset/ConfirmSkipLevelPopup.png" />
          </button>
        </div>
        <div className="flex w-[12.5%] -ml-3 mt-20">
          <img src="uiAsset/ClosePopupButton.png" onClick={handleCancelSkip} />
        </div>
      </div>
    </Modal>
  );
};
