import { useState } from "react";
import Modal from "../Modal";
import { YourPrices } from "./PrizesTab/YourPrizes";
import { Inventory } from "./PrizesTab/Inventory";
import { useStoreState } from "../../game/util/hook";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  zIndex?: number;
}

export const PrizesPopup = ({ isOpen, closeModal, zIndex }: ModalProps) => {
  const [choosingTab, setChoosingTab] = useState<"YourPrices" | "Inventory">(
    "YourPrices"
  );

  const inventory = useStoreState((state) => state.inventory);
  const [filterItemType, setFilterItemType] = useState("All");
  const [filterVoucher, setFilterVoucherType] = useState("All");

  return (
    <Modal
      visible={isOpen}
      onDismiss={closeModal}
      dismissOnBackdropClick={false}
      zIndex={zIndex}
      backdropColor="rgba(0, 0, 0, 0.8)"
    >
      <div className="flex max-w-sm justify-center items-center -mt-10">
        <img src="uiAsset/PrizeBackgroundPopup.png" className=" w-[90%] ml-2" />
        <div className="flex -mt-72 w-48 h-[4.25%] rounded-full justify-between items-center absolute">
          <div
            onClick={() => setChoosingTab("YourPrices")}
            className={`flex items-center justify-center w-1/2 h-full rounded-l-full transition ${
              choosingTab === "YourPrices"
                ? "text-white bg-[#3074f4]"
                : "text-[#7d8085] bg-[#e8e8e8]"
            }`}
          >
            <div className="text-bold text-sm">Your Prices</div>
          </div>
          <div
            onClick={() => setChoosingTab("Inventory")}
            className={`flex items-center justify-center w-1/2 h-full rounded-r-full transition ${
              choosingTab === "Inventory"
                ? "text-white bg-[#3074f4]"
                : "text-[#7d8085] bg-[#e8e8e8]"
            }`}
          >
            <div className="-ml-1 text-bold text-sm">Inventory</div>
          </div>
        </div>

        <div className="flex max-w-[20rem] -mt-48 absolute z-20  justify-center">
          <div className="flex justify-center items-center w-[80%] ">
            <img src="uiAsset/SelectionButton.png" />
            <div className="flex w-[80%] absolute justify-between items-center">
              {choosingTab === "YourPrices" && (
                <select
                  className=" z-30 ml-4 w-[90%] appearance-none bg-transparent border-none outline-none text-blue-700 font-bold"
                  value={filterItemType}
                  onChange={(e) => setFilterItemType(e.target.value)}
                >
                  <option value={"All"}>All</option>
                </select>
              )}
              {choosingTab === "Inventory" && (
                <select
                  className=" z-30 ml-4 w-[90%] appearance-none bg-transparent border-none outline-none text-blue-700 font-bold"
                  value={filterVoucher}
                  onChange={(e) => setFilterVoucherType(e?.target?.value)}
                >
                  <option value={"All"}>All</option>
                  <option value={"eVoucher Got It 1.000.000 VND"}>
                    eVoucher Got It 1.000.000 VND
                  </option>
                  <option value={"eVoucher Got It 800.000 VND"}>
                    eVoucher Got It 800.000 VND
                  </option>
                  <option value={"eVoucher Got It 600.000 VND"}>
                    eVoucher Got It 600.000 VND
                  </option>
                  <option value={"eVoucher Got It 400.000 VND"}>
                    eVoucher Got It 400.000 VND
                  </option>
                  <option value={"eVoucher Got It 200.000 VND"}>
                    eVoucher Got It 200.000 VND
                  </option>
                </select>
              )}
              <img src="uiAsset/Vector.png" className="mr-4 w-5 h-3 " />
            </div>
          </div>
          {choosingTab === "YourPrices" ? (
            <YourPrices filterItem={filterItemType} />
          ) : (
            <Inventory filterVoucher={filterVoucher} />
          )}
        </div>
        <img
          src="uiAsset/ClosePopupButton.png"
          className="absolute bottom-10 h-14"
          onClick={closeModal}
        />
      </div>
    </Modal>
  );
};
