import { useEffect, useState } from "react";
import { ASSETS } from "../../game/util/assets";
import { emitter } from "../../game/util/emitter";
import { useStoreActions, useStoreState } from "../../game/util/hook";
import { Game } from "./Game";
import { SystemGameRender } from "./SystemGameRender";

const GameScreen = () => {
  const instructGame = useStoreState((state) => state.instructMode);
  const setInstructGame = useStoreActions((actions) => actions.setInstructMode);
  const [hasCountTimeSound, setCountTimeSound] = useState(false);

  useEffect(() => {
    if (!instructGame) {
      return;
    }

    emitter.on("offInstructMode", () => setInstructGame(false));
    return () => {
      emitter.off("offInstructMode", () => setInstructGame(false));
    };
  }, [instructGame, setInstructGame]);

  return (
    <div
      className={`flex flex-1 flex-col w-full h-full items-center relative backdrop-brightness-100`}
      style={{
        backgroundImage: `url(${ASSETS.MAINGAME_BG})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingTop: "var(--inset-top)",
        paddingBottom: " var(--inset-bottom)",
        boxSizing: "border-box",
      }}
    >
      {instructGame && (
        <div className="flex absolute w-full h-full scale-125 -z-10">
          <img
            src={`${ASSETS.MAINGAME_BG}`}
            className={` w-full h-full brightness-50 `}
          />
        </div>
      )}
      <div className="flex absolute w-full h-full -z-10"></div>
      <div className="flex flex-col max-w-[25rem] w-full h-full">
        <SystemGameRender
          hasCountTimeSound={hasCountTimeSound}
          setCountTimeSound={setCountTimeSound}
        />
        <Game
          mode={"10x10"}
          instructGame={instructGame}
          setCountTimeSound={setCountTimeSound}
        />
      </div>
    </div>
  );
};

export default GameScreen;
