import { Color } from "gdxts";
import { adjustAlpha } from "./util/color";

export const USER_TOKEN_KEY = "@userToken";
export const USER_TOKEN_HEADER = "authorization";

export const WORLD_WIDTH = 575;
export const WORLD_HEIGHT = 610;

export const SCREEN_WIDTH = 600;
export const SCREEN_HEIGHT = 800;
export const OFFSET = 10;

export const calculateDimensions = () => {
  const COL = 8;
  const ROW = 8;

  const PADDING = 7;
  const BORDER = 2;
  const GRID_SIZE = (WORLD_WIDTH - PADDING * 2 - BORDER * (COL + 1)) / COL;
  const START_Y =
    (WORLD_HEIGHT - (GRID_SIZE + BORDER) * ROW - BORDER) / 2 + 160;

  const BOARD_WIDTH = GRID_SIZE * COL + BORDER * (COL + 1);
  const BOARD_HEIGHT = GRID_SIZE * ROW + BORDER * (ROW + 1);

  return {
    COL,
    ROW,
    PADDING,
    BORDER,
    GRID_SIZE,
    START_Y,
    BOARD_WIDTH,
    BOARD_HEIGHT,
  };
};

export const dimensionInfo = {
  dimensions: calculateDimensions(),
};

export const LINE_COLORS = [
  adjustAlpha("B4F1FF", 1),
  adjustAlpha("B2A4FF", 1),
  adjustAlpha("FFB4B4", 1),
  adjustAlpha("FFDEB4", 1),
  adjustAlpha("FDF7C3", 1),
];

export const CURRENT_LINE_COLOR = Color.fromString("cccccc");
