import { useState } from "react";
import Modal from "../Modal";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;

  zIndex?: number;
}

export const InstructionPopup = ({
  isOpen,
  closeModal,
  zIndex,
}: ModalProps) => {
  const [choosingTab, setChoosingTab] = useState<"Rules" | "HowToPlay">(
    "Rules"
  );

  return (
    <Modal
      visible={isOpen}
      onDismiss={closeModal}
      dismissOnBackdropClick={false}
      zIndex={zIndex}
      backdropColor="rgba(0, 0, 0, 0.8)"
    >
      <div className="flex max-w-sm justify-center items-center -mt-10">
        <img
          src="uiAsset/InstructionBackgroundPopup.png"
          className=" w-[90%]"
        />
        <div className="flex -mt-80 w-48 h-[4.25%] rounded-full justify-between items-center absolute z-50">
          <div
            onClick={() => setChoosingTab("Rules")}
            className={`flex items-center justify-center w-1/2 h-full rounded-l-full transition ${
              choosingTab === "Rules"
                ? "text-white bg-[#3074f4]"
                : "text-[#7d8085] bg-[#e8e8e8]"
            }`}
          >
            <div className="text-bold text-sm">Rules</div>
          </div>
          <div
            onClick={() => setChoosingTab("HowToPlay")}
            className={`flex items-center justify-center w-1/2 h-full rounded-r-full transition ${
              choosingTab === "HowToPlay"
                ? "text-white bg-[#3074f4]"
                : "text-[#7d8085] bg-[#e8e8e8]"
            }`}
          >
            <div className="-ml-1 text-bold text-sm">How to play</div>
          </div>
        </div>

        <div className="flex max-w-[20rem] -mt-48 absolute   justify-center">
          <div className="w-full max-w-xs ml-2 max-h-[23rem] mt-80 overflow-scroll ">
            <div className="pl-1.5 pr-1.5">
              {choosingTab === "Rules" && (
                <>
                  <div className="flex">
                    <img src="uiAsset/dot.png" className="h-2 mt-2" />
                    <p className="ml-2">
                      Player will receive unlimited turns during the program.
                      Player will answer questions displayed on the screen,
                      Smiley face - (
                      <img
                        src="uiAsset/PointIcon.png"
                        className="inline h-6 w-[1.45rem] -mt-1"
                      />
                      ) will be calculated as in the {"<How to Score>"}.
                    </p>
                  </div>
                  <br />

                  <div className="flex">
                    <img src="uiAsset/dot.png" className="h-2 mt-2" />
                    <p className="ml-2 text-blue-500">
                      Total number of questions/ screen:
                      <span className="font-bold"> 3</span>
                    </p>
                  </div>
                  <br />

                  <div className="flex">
                    <img src="uiAsset/dot.png" className="h-2 mt-2" />
                    <p className="ml-2">
                      Questions and Answers (Q&A) will be selected randomly from
                      Questionaire Bank (divided into 11 topics, 11 Q&A for each
                      topic). Each screen has 3 Q&A from the same topic..
                    </p>
                  </div>
                  <br />

                  <div className="flex">
                    <img src="uiAsset/dot.png" className="h-2 mt-2" />
                    <p className="ml-2">
                      For each turn, Player will answer correctly all questions
                      to go next screen.
                    </p>
                  </div>
                  <br />

                  <div className="flex">
                    <img src="uiAsset/dot.png" className="h-2 mt-2" />
                    <p className="ml-2">
                      Time up: 120s {"=>"} end game. Start game again from
                      current screen.
                    </p>
                  </div>
                </>
              )}
              {choosingTab === "HowToPlay" && (
                <>
                  <div className="flex">
                    <img src="uiAsset/dot.png" className="h-2 mt-2" />
                    <p className="ml-2">
                      Player access the Shinhan SOL Vietnam application (SOL
                      application) to participate in the game and check the
                      accumulated Smiley faces - (
                      <img
                        src="uiAsset/PointIcon.png"
                        className="inline h-6 w-[1.45rem] -mt-1"
                      />
                      ).
                    </p>
                  </div>
                  <br />
                  <div className="flex">
                    <img src="uiAsset/dot.png" className="h-2 mt-2" />
                    <p className="ml-2">
                      Player answers CS questions by choosing consecutive letter
                      words displayed on the random alphabet board.
                    </p>
                  </div>
                  <br />
                  <div className="flex">
                    <img src="uiAsset/dot.png" className="h-2 mt-2" />
                    <p className="ml-2">
                      Player answer fast will receive bonus Smiley face - (
                      <img
                        src="uiAsset/PointIcon.png"
                        className="inline h-6 w-[1.45rem] -mt-1"
                      />
                      ).
                    </p>
                  </div>
                  <br />
                  <div className="flex">
                    <img src="uiAsset/dot.png" className="h-2 mt-2" />
                    <p className="ml-2">
                      Time up for each screen: 120s, if player fail to answer
                      within 120s {"=>"} end of session {">>>"} start the game
                      at current screen.
                    </p>
                  </div>
                  <br />
                  <div className="flex">
                    <img src="uiAsset/dot.png" className="h-2 mt-2" />
                    <p className="ml-2">
                      The score is based on the number of correct answer and the
                      second remaning before time up.
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <img
          src="uiAsset/ClosePopupButton.png"
          className="absolute bottom-10 h-14"
          onClick={closeModal}
        />
      </div>
    </Modal>
  );
};
