import { Align, Color, GlyphLayout, Texture, Vector2 } from "gdxts";
import {
  CURRENT_LINE_COLOR,
  LINE_COLORS,
  OFFSET,
  WORLD_HEIGHT,
  WORLD_WIDTH,
  dimensionInfo,
} from "../Constants";
import { System } from "../util/sysman";
import { GameManager } from "../initGame";
import { Line } from "../types";
import { calculateLineCap } from "../util/gridHelper";

export const registerGridRenderSystem = (manager: GameManager) => {
  manager.addSystem((): System => {
    const state = manager.context.state;
    const batch = manager.context.batch;
    const whiteTexture = Texture.createWhiteTexture(manager.context.gl);
    const circle = manager.context.assets.getTexture("circle")!;

    const font = manager.context.assets.getFont("normal")!;
    font.data.setXYScale(0.35);
    if (dimensionInfo.dimensions.COL === 15) {
      font.data.setXYScale(0.25);
    }

    const layout = new GlyphLayout();
    layout.setText(
      font,
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      0,
      26,
      Color.WHITE,
      1000,
      Align.center,
      false
    );
    const textHeight = layout.height;

    const TEXT_PADDING = (dimensionInfo.dimensions.GRID_SIZE - textHeight) / 2;

    const snappedCurrentLine: Line = {
      x1: -1,
      y1: -1,
      x2: -1,
      y2: -1,
    };

    const tmpV1 = new Vector2();
    const tmpV2 = new Vector2();

    const cellPos = new Vector2();
    const getCellPos = (x: number, y: number) => {
      cellPos.set(
        x *
          (dimensionInfo.dimensions.GRID_SIZE +
            dimensionInfo.dimensions.BORDER) +
          dimensionInfo.dimensions.PADDING +
          dimensionInfo.dimensions.BORDER +
          10,
        y *
          (dimensionInfo.dimensions.GRID_SIZE +
            dimensionInfo.dimensions.BORDER) +
          dimensionInfo.dimensions.START_Y +
          dimensionInfo.dimensions.BORDER
      );
      return cellPos;
    };

    const CIRCLE_SIZE = dimensionInfo.dimensions.GRID_SIZE * 0.8;

    const drawLineV1 = new Vector2();
    const drawLineV2 = new Vector2();
    const drawLine = (line: Line, color: Color) => {
      let pos = getCellPos(line.x1, line.y1);
      const startX = pos.x + dimensionInfo.dimensions.GRID_SIZE / 2;
      const startY = pos.y + dimensionInfo.dimensions.GRID_SIZE / 2;
      batch.setColor(color);
      batch.draw(
        circle,
        startX - CIRCLE_SIZE / 2,
        startY - CIRCLE_SIZE / 2,
        CIRCLE_SIZE,
        CIRCLE_SIZE
      );
      pos = getCellPos(line.x2, line.y2);
      const endX = pos.x + dimensionInfo.dimensions.GRID_SIZE / 2;
      const endY = pos.y + dimensionInfo.dimensions.GRID_SIZE / 2;
      batch.draw(
        circle,
        endX - CIRCLE_SIZE / 2,
        endY - CIRCLE_SIZE / 2,
        CIRCLE_SIZE,
        CIRCLE_SIZE
      );

      const rectCenterX = (startX + endX) / 2;
      const rectCenterY = (startY + endY) / 2;

      batch.draw(
        circle,
        rectCenterX - CIRCLE_SIZE / 2,
        rectCenterY - CIRCLE_SIZE / 2,
        CIRCLE_SIZE,
        CIRCLE_SIZE
      );

      drawLineV1.set(startX, startY);
      drawLineV2.set(endX, endY);

      const angle = Math.atan2(endY - startY, endX - startX);
      const distance = drawLineV1.distance(drawLineV2);

      batch.draw(
        whiteTexture,
        rectCenterX - distance / 2,
        rectCenterY - CIRCLE_SIZE / 2,
        distance,
        CIRCLE_SIZE,
        distance / 2,
        CIRCLE_SIZE / 2,
        angle
      );

      batch.setColor(Color.WHITE);
    };

    // const drawWordSuggest = (x: number, y: number) => {
    //   const pos = getCellPos(x, y);
    //   batch.setColor(Color.GREEN);
    //   batch.draw(
    //     circle,
    //     pos.x + dimensionInfo.dimensions.GRID_SIZE / 2 - CIRCLE_SIZE / 2,
    //     pos.y + dimensionInfo.dimensions.GRID_SIZE / 2 - CIRCLE_SIZE / 2,
    //     CIRCLE_SIZE,
    //     CIRCLE_SIZE
    //   );
    //   batch.setColor(Color.WHITE);
    // };

    return {
      process() {
        const grid = state.grid;
        if (grid === undefined) {
          return;
        }

        batch.draw(whiteTexture, 15, 137, WORLD_HEIGHT - 40, 100);

        batch.setColor(Color.fromString("D7D7FF"));
        batch.draw(
          whiteTexture,
          dimensionInfo.dimensions.PADDING + OFFSET,
          dimensionInfo.dimensions.START_Y,
          dimensionInfo.dimensions.BOARD_WIDTH,
          dimensionInfo.dimensions.BOARD_HEIGHT
        );
        batch.setColor(Color.WHITE);

        for (let x = 0; x < dimensionInfo.dimensions.COL; x++) {
          for (let y = 0; y < dimensionInfo.dimensions.ROW; y++) {
            const charX =
              x *
                (dimensionInfo.dimensions.GRID_SIZE +
                  dimensionInfo.dimensions.BORDER) +
              dimensionInfo.dimensions.PADDING +
              dimensionInfo.dimensions.BORDER;
            const charY =
              y *
                (dimensionInfo.dimensions.GRID_SIZE +
                  dimensionInfo.dimensions.BORDER) +
              dimensionInfo.dimensions.START_Y +
              dimensionInfo.dimensions.BORDER;

            const cell = grid[y][x];
            if (cell) {
              batch.setColor(Color.WHITE);
              batch.draw(
                whiteTexture,
                charX + OFFSET,
                charY,
                dimensionInfo.dimensions.GRID_SIZE,
                dimensionInfo.dimensions.GRID_SIZE
              );
            }
          }
        }

        if (state.dragging) {
          if (state.currentLineDirty) {
            tmpV1.set(state.currentLine.x1, state.currentLine.y1);
            tmpV2.set(state.currentLine.x2, state.currentLine.y2);
            calculateLineCap(tmpV1, tmpV2, snappedCurrentLine);
          }
          drawLine(snappedCurrentLine, CURRENT_LINE_COLOR);
        }
        let index = 0;
        for (const line of state.foundLines) {
          const color = LINE_COLORS[index % LINE_COLORS.length];
          drawLine(line, color);
          index++;
        }

        // if (state.answersSuggested.length) {
        //   for (let i = 0; i < state.answersSuggested.length; i += 2) {
        //     drawWordSuggest(
        //       state.answersSuggested[i],
        //       state.answersSuggested[i + 1]
        //     );
        //   }
        // }

        for (let x = 0; x < dimensionInfo.dimensions.COL; x++) {
          for (let y = 0; y < dimensionInfo.dimensions.ROW; y++) {
            const charX =
              x *
                (dimensionInfo.dimensions.GRID_SIZE +
                  dimensionInfo.dimensions.BORDER) +
              dimensionInfo.dimensions.PADDING +
              dimensionInfo.dimensions.BORDER;
            const charY =
              y *
                (dimensionInfo.dimensions.GRID_SIZE +
                  dimensionInfo.dimensions.BORDER) +
              dimensionInfo.dimensions.START_Y +
              dimensionInfo.dimensions.BORDER;

            const cell = grid[y][x];
            if (cell) {
              batch.setColor(0, 0, 0, 1);
              font.draw(
                batch,
                cell,
                charX + OFFSET,
                charY + TEXT_PADDING,
                dimensionInfo.dimensions.GRID_SIZE,
                Align.center
              );
              batch.setColor(Color.WHITE);
            }
          }
        }
      },
      dispose() {
        whiteTexture.dispose();
      },
    };
  });
};
