import { useCallback, useState } from "react";
import Modal from "../Modal";
import gamefoxSDK from "../../game/util/gameFoxSDK";
import config from "../../config";
import { setInstructViewed } from "../../game/util/storage";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export const AcceptRulePopup = ({ isOpen, closeModal }: ModalProps) => {
  const [tickbox, setTickbox] = useState(false);

  const openExternalLink = useCallback((link: string) => {
    gamefoxSDK.postMessage({
      eventType: "LINK_CLICKED",
      eventData: {
        link,
      },
    });
  }, []);

  return (
    <Modal
      visible={isOpen}
      onDismiss={closeModal}
      dismissOnBackdropClick={false}
      backdropColor="rgba(0, 0, 0, 0.8)"
    >
      <div className="flex justify-center">
        <div className="flex flex-col w-[90%] max-w-md justify-center ">
          <img src="uiAsset/AcceptRulesPopup.png" />
          <div className="absolute flex w-full max-w-md justify-center items-center -ml-4 mt-[22.5vh]">
            <div className="" onClick={() => setTickbox(!tickbox)}>
              {tickbox ? (
                <img src="uiAsset/Tickedbox.png" className="h-8 w-8 mr-4" />
              ) : (
                <img src="uiAsset/TickboxNone.png" className="h-8 w-8 mr-4" />
              )}
            </div>
            <div className="">
              <div className="text-sm">I agree with Term and Conditions</div>
              <div className="text-xs text-[#9B9B9B]">
                Tôi đồng ý với thể lệ chương trình
              </div>
              <div
                className="text-[#3270EA] text-sm  mt-0.5"
                onClick={() => openExternalLink(config.enRuleLink)}
              >
                See details [Term and Conditions]
              </div>
              <div
                className="text-xs text-[#3270EA]"
                onClick={() => openExternalLink(config.viRuleLink)}
              >
                Xem chi tiết [Thể lệ chương trình]
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            {!tickbox ? (
              <img
                src="uiAsset/PlayNowDisabledEN.png"
                className="w-3/5 -mt-10"
              />
            ) : (
              <img
                src="uiAsset/PlayNowActiveEN.png"
                className="w-3/5 -mt-10"
                onClick={() => {
                  setInstructViewed(true);
                  closeModal();
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
