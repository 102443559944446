import { ASSETS } from "../../game/util/assets";
import Modal from "../Modal";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  zIndex?: number;
}

const RenderSlide = () => {
  const imgs = [];
  for (let i = 1; i < 20; i++) {
    imgs.push(
      <img
        key={i}
        src={`uiAsset/handbook/Game4-handbook-${i < 10 ? "0" + i : i}.png`}
      />
    );
  }
  return imgs;
};

export const HandBookPopup = ({ isOpen, closeModal, zIndex }: ModalProps) => {
  return (
    <Modal
      visible={isOpen}
      onDismiss={closeModal}
      dismissOnBackdropClick={false}
      zIndex={zIndex}
      backdropColor="rgba(0, 0, 0, 0.8)"
    >
      <img
        src={`${ASSETS.QUIT_BUTTON}`}
        className="absolute bottom-5 w-10 h-10 "
        onClick={() => closeModal()}
      />

      <div className="flex w-[95%] max-w-sm">
        <div className="flex flex-col gap-10 overflow-scroll h-[80vh] scroll-smooth">
          {RenderSlide()}
        </div>
      </div>
    </Modal>
  );
};
