import { useCallback, useState } from "react";
import Modal from "../Modal";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export const SorryPopup = ({ isOpen, closeModal }: ModalProps) => {
  return (
    <Modal
      visible={isOpen}
      onDismiss={closeModal}
      dismissOnBackdropClick={false}
      backdropColor="rgba(0, 0, 0, 0.8)"
    >
      <div className="flex justify-center">
        <div className="flex flex-col w-[90%] max-w-md justify-center ">
          <img src="uiAsset/SorryPopup.png" onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
};
