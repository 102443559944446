import { GameState, Line } from "../game/types";

const dummyGameState: GameState = {
  grid: undefined,
  words: undefined,
  originalWords: undefined,
  currentLine: {
    x1: -1,
    y1: -1,
    x2: -1,
    y2: -1,
  } as Line,
  currentLineDirty: true,
  foundLines: [] as Line[],
  dragging: false,
};

export const getDummyGameState = (): GameState => {
  return dummyGameState;
};
