/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from "react";
import { ASSETS } from "../../game/util/assets";
import gamefoxSDK from "../../game/util/gameFoxSDK";
import { TotalRank } from "./playerLeaderBoardPopup/TotalRank";
import { WeekRank } from "./playerLeaderBoardPopup/WeekRank";
import { PreLeaderboardPopup } from "./PreLeaderboardPopup";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  soundState: boolean;
  handleSound: any;
}

export const LeaderBoardPopup = ({
  isOpen,
  closeModal,
  soundState,
  handleSound,
}: ModalProps) => {
  const [choosingTab, setChoosingTab] = useState<"Final" | "Week">("Final");
  const [totalLeaderboardData, setTotalLeaderboardData] = useState([]);
  const [weekLeaderboardData, setWeekLeaderboardData] = useState([]);
  const [totalPosition, setTotalPosition] = useState(0);
  const [weekPosition, setWeekPosition] = useState(0);
  const [openPreleaderBoard, setOpenPreleaderBoard] = useState(true);

  const refreshTotalLeaderboardData = useCallback(async () => {
    const leaderboardData = await gamefoxSDK.getLeaderboard(
      gamefoxSDK.getParams().campaignId,
      {
        wordSearchScore: "desc",
      },
      53
    );
    const sortedData = leaderboardData.leaderboard.sort(
      (a, b) => b.progressData.wordSearchScore - a.progressData.wordSearchScore
    );
    setTotalLeaderboardData(sortedData);
    setTotalPosition(leaderboardData.position);
  }, []);

  const refreshWeekLeaderboardData = useCallback(async () => {
    const leaderboardData = await gamefoxSDK.getLeaderboard(
      gamefoxSDK.getParams().campaignId,
      {
        wordSearchWeekScore: "desc",
      },
      53
    );
    const sortedData = leaderboardData.leaderboard.sort(
      (a, b) =>
        b.progressData.wordSearchWeekScore - a.progressData.wordSearchWeekScore
    );
    setWeekPosition(leaderboardData.position);
    setWeekLeaderboardData(sortedData);
  }, []);

  useEffect(() => {
    refreshTotalLeaderboardData();
    refreshWeekLeaderboardData();
  }, [refreshTotalLeaderboardData, refreshWeekLeaderboardData]);

  return (
    isOpen && (
      <div className="flex flex-col w-full h-full ">
        <div className="absolute w-full h-full">
          {/* <img src={`${ASSETS.MAINGAME_BG}`} className="w-full h-full" /> */}
        </div>
        <div className="flex justify-between w-full z-20">
          <img
            src={`${ASSETS.GO_BACK_BUTTON}`}
            onClick={closeModal}
            className="mt-2 w-10 h-10 ml-4"
          />
          <div className="flex" onClick={handleSound}>
            {soundState ? (
              <img
                src={`${ASSETS.SOUND_ON_BUTTON}`}
                className="mt-2 w-10 h-10 mr-4"
              />
            ) : (
              <img
                src={`${ASSETS.SOUND_OFF_BUTTON}`}
                className="mt-2 w-10 h-10 mr-4"
              />
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex justify-center w-full z-10  pointer-events-none">
            <img src={`${ASSETS.GAME_NAME}`} className="w-[75%] -mt-14 -ml-2" />
          </div>
          <div className="flex flex-col w-full ">
            <div className="flex flex-col w-full h-[62vh] z-30 -mt-10 items-center ">
              <img
                src={ASSETS.BANNER_LEADERBOARD}
                className="absolute w-[85%] -mt-[3vh] ml-6 z-10"
              />
              <img
                src={ASSETS.BOARD_OF_LEADERBOARD}
                className="w-[95%] h-full mt-[10vh] "
              />
            </div>
            <div className="flex w-full h-full flex-col z-30 mt-[8vh] items-center absolute">
              <div className="flex w-44 h-[4.5%] rounded-full justify-between  z-30">
                <div
                  onClick={() => setChoosingTab("Final")}
                  className={`flex items-center justify-center w-1/2 h-full rounded-l-full transition ${
                    choosingTab === "Final"
                      ? "text-white bg-[#3074f4]"
                      : "text-[#7d8085] bg-[#e8e8e8]"
                  }`}
                >
                  <div className="text-bold text-sm">Final</div>
                </div>
                <div
                  onClick={() => setChoosingTab("Week")}
                  className={`flex items-center justify-center w-1/2 h-full rounded-r-full transition ${
                    choosingTab === "Week"
                      ? "text-white bg-[#3074f4]"
                      : "text-[#7d8085] bg-[#e8e8e8]"
                  }`}
                >
                  <div className="-ml-1 text-bold text-sm">Week</div>
                </div>
              </div>
              {choosingTab === "Final" ? (
                <TotalRank
                  leaderboard={totalLeaderboardData}
                  position={totalPosition}
                />
              ) : (
                <WeekRank
                  leaderboard={weekLeaderboardData}
                  position={weekPosition}
                />
              )}
            </div>
          </div>
        </div>
        <PreLeaderboardPopup
          isOpen={openPreleaderBoard}
          closeModal={() => setOpenPreleaderBoard(false)}
        />
      </div>
    )
  );
};
