import { randomIntInRange } from "./random";

export const enum Direction {
  Horizontal,
  Vertical,
  DiagonalUp,
  DiagonalDown,
}

export default Direction;

export const randomDirection = (): Direction => {
  return randomIntInRange(0, Direction.Vertical);
};

export const getWordRowCol = (
  direction: Direction,
  row: number,
  col: number,
  index: number
) => {
  let r = row;
  let c = col;

  if (direction === Direction.Horizontal) {
    r += index;
  }

  if (direction === Direction.Vertical) {
    c += index;
  }

  return { row: r, col: c, direction: direction };
};
