import data from "../../data/questions.json";

export const getRandomIndices = (count: number, max: number): number[] => {
  const indices = new Set<number>();
  while (indices.size < count) {
    indices.add(Math.floor(Math.random() * max));
  }
  return Array.from(indices);
};

function getRandomTopicQuestionsAndAnswers(data: Record<string, any>): {
  topic: string;
  questions: Array<{ question: string; answer: string }>;
} {
  const categories = Object.keys(data);
  const randomCategory =
    categories[Math.floor(Math.random() * categories.length)];

  const categoryData = data[randomCategory];

  for (let i = categoryData.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [categoryData[i], categoryData[j]] = [categoryData[j], categoryData[i]];
  }

  const selectedData = categoryData
    .slice(0, 3)
    .map((item: { question: string; answer: string }) => ({
      question: item.question,
      answer: item.answer,
    }));

  return { topic: randomCategory, questions: selectedData };
}

export const getRandomQuestions = () => {
  return getRandomTopicQuestionsAndAnswers(data);
};
