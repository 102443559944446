import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import GameScreen from "./components/game/GameScreen";
import PlayScreen from "./components/play/PlayScreen";
import initSdk from "./game/util/initSdk";
import Loading from "./components/loading/LoadingScreen";
import { StoreProvider } from "easy-peasy";
import store from "./game/store";
import { useStoreState } from "./game/util/hook";

const FallbackRoute = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate({ pathname: "/play", search: window.location.search });
  }, [navigate]);

  return null;
};

const App = () => {
  // const user = useStoreState((state) => state.user);

  return (
    <Routes>
      <>
        <Route path="/" element={<Loading />} />
        <Route path="/play" element={<PlayScreen />} />
        <Route path="/game" element={<GameScreen />} />
        <Route path="*" element={<FallbackRoute />} />
      </>
    </Routes>
  );
};

const WrappedApp = () => {
  const [inited, setInited] = useState(false);

  useEffect(() => {
    if (!inited) {
      initSdk();
    }
    setInited(true);
  }, [inited]);

  if (!inited) {
    return null;
  }

  return (
    <StoreProvider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StoreProvider>
  );
};

export default WrappedApp;
