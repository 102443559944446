/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ASSETS } from "../../game/util/assets";
import gamefoxSDK from "../../game/util/gameFoxSDK";
import {
  useRefreshInventory,
  useRefreshNotifications,
  useRefreshUser,
  useStoreActions,
  useStoreState,
} from "../../game/util/hook";
import { AudioType, soundUtils } from "../../game/util/soundUtil";
import {
  getMusicSetting,
  getSoundFXSetting,
  setInstructViewed,
  setMusicSetting,
  setSoundFXSetting,
} from "../../game/util/storage";
import { AcceptRulePopup } from "../popup/AcceptRulePopup";
import { BonusPointPopup } from "../popup/BonusPointPopup";
import { InstructionPopup } from "../popup/InstructionPopup";
import { LeaderBoardPopup } from "../popup/LeaderBoardPopup";
import { PrizesPopup } from "../popup/PrizesPopup";
import { TotalRankPopup } from "../popup/RankPopup/TotalRankPopup";
import { WeekRankPopup } from "../popup/RankPopup/WeekRankPopup";
import { SuggestPopup } from "../popup/SuggestPopup";
import ProgressBar from "./ProgressBar";
import { HandBookPopup } from "../popup/HandBookPopup";
import { SorryPopup } from "../popup/SorryPopup";
import dayjs from "dayjs";
import { EndGamePopup } from "../popup/EndGamePopup";

const PlayScreen = () => {
  const [openPrizesPopup, setOpenPrizesPopup] = useState(false);
  const [openIntructionPopup, setOpenIntructionPopup] = useState(false);
  const [openLeaderBoardPopup, setOpenLeaderBoardPopup] = useState(false);
  const [openAcceptRulesPopup, setOpenAcceptRulesPopup] = useState(false);
  const [openSuggestGiftPopup, setOpenSuggestGiftPopup] = useState(false);
  const [openBonusPointPopup, setOpenBonusPointPopup] = useState(false);
  const [openWeekRankPopup, setOpenWeekRankPopup] = useState(false);
  const [openTotalRankPopup, setOpenTotalRankPopup] = useState(false);
  const [openHandBookPopup, setOpenHandBookPopup] = useState(false);

  const [isSoundFxOn, setIsSoundFxOn] = useState(true);
  const [isMusicOn, setIsMusicOn] = useState(true);
  const navigate = useNavigate();

  const refreshInventory = useRefreshInventory();
  const refreshUser = useRefreshUser();
  const refreshNotifications = useRefreshNotifications();

  const inventory = useStoreState((state) => state.inventory);
  const user = useStoreState((state) => state.user);
  const instructMode = useStoreState((state) => state.instructMode);
  // const notifications = useStoreState((state) => state.notifications);
  const tabletMode = useStoreState((state) => state.tabletMode);
  const gameConfigs = useStoreState((state) => state.gameConfigs);
  const sorryPopup = useStoreState((state) => state.sorryPopup);
  const setSorryPopup = useStoreActions((state) => state.setSorryPopup);
  const [openSorryPopup, setOpenSorryPopup] = useState(false);

  const [openEndGamePopup, setOpenEndGamePopup] = useState(true);
  const setGameConfig = useStoreActions(
    (actions: any) => actions.setGameConfig
  );
  const setGotHint = useStoreActions((actions) => actions.setGotHint);

  useEffect(() => {
    if (instructMode) {
      setOpenAcceptRulesPopup(true);
    }
    gamefoxSDK.checkin().then(async (result) => {
      console.log("loginDaily", result);
      if (result.reward?.length) {
        setOpenSuggestGiftPopup(true);
        setGotHint(true);
      }
    });
  }, [instructMode, setGotHint]);

  const [weekData, setWeekData] = useState({});

  const handlePlay = useCallback(() => {
    navigate({ pathname: "/game", search: window.location.search });

    setTimeout(() => {
      // soundUtils.play(MusicId.THEME);
    }, 300);
  }, [navigate]);

  const auth = useCallback(async () => {
    await gamefoxSDK.auth();
  }, []);

  useEffect(() => {
    auth();
    refreshInventory();
    refreshUser();
    refreshNotifications();
  }, [auth, refreshInventory, refreshNotifications, refreshUser]);

  const getNotification = useCallback(async () => {
    const notification = await gamefoxSDK.getNotifications();
    console.log("notification", notification);
    if (notification.length) {
      const weekRankData = notification.filter(
        (noti: { data: any }) => noti?.data.type === "SH4_REWARD_WEEKLY"
      );
      setWeekData(weekRankData[0]);
      setOpenWeekRankPopup(true);
    }
  }, []);

  const ackNoti = useCallback(async () => {
    await gamefoxSDK.ackNotifications();
  }, []);

  useEffect(() => {
    getNotification();
  }, [getNotification]);

  useEffect(() => {
    if (sorryPopup) {
      //...
      setOpenSorryPopup(true);
    }
  }, [setSorryPopup, sorryPopup]);

  useEffect(() => {
    if (user && user.progressData && user.progressData.wordSearchScore) {
      setGameConfig({
        ...gameConfigs,
        score: user.progressData.wordSearchScore,
      });
    }
  }, [setGameConfig, user]);

  useEffect(() => {
    const isEnableMusic = getMusicSetting();
    setIsMusicOn(isEnableMusic);
    const isEnableSoundFx = getSoundFXSetting();
    setIsSoundFxOn(isEnableSoundFx);
  }, []);

  const onUpdateMusicSetting = useCallback(async (on: boolean) => {
    if (soundUtils.mute(AudioType.MUSIC, !on)) {
      soundUtils.setMusicEnable(on);
      setMusicSetting(on);
      setIsMusicOn(on);
      soundUtils.muteAll(false);
    } else {
      soundUtils.muteAll(true);
      soundUtils.setMusicEnable(false);
      soundUtils.setSoundFxEnable(false);
    }
  }, []);

  const onUpdateSoundFXSetting = useCallback(async (on: boolean) => {
    if (soundUtils.mute(AudioType.SOUND_FX, !on)) {
      soundUtils.setSoundFxEnable(on);
      setSoundFXSetting(on);
      setIsSoundFxOn(on);
    }
  }, []);

  const onUpdateSound = useCallback(() => {
    const currentState = getMusicSetting();
    const newState = !currentState;
    onUpdateMusicSetting(newState);
    onUpdateSoundFXSetting(newState);
  }, [onUpdateMusicSetting, onUpdateSoundFXSetting]);

  const onExitGame = useCallback(() => {
    gamefoxSDK.postMessage({
      eventType: "EXIT_GAME",
      eventData: {},
    });
  }, []);

  return (
    <div
      className="flex flex-1 flex-col h-full w-full items-center "
      style={{
        backgroundImage: `url(${ASSETS.HOMESCREEN_BG})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingTop: "var(--inset-top)",
        paddingBottom: " var(--inset-bottom)",
        boxSizing: "border-box",
      }}
    >
      {!openLeaderBoardPopup && (
        <div className="flex justify-center">
          <div className="absolute h-full w-full -z-10">
            {tabletMode ? (
              <div className="flex w-full h-full">
                <img
                  src="uiAsset/MainBigScreen.png"
                  className="h-full w-full"
                />
              </div>
            ) : (
              <div className="flex w-full h-full">
                {/* <img
                  src={`${ASSETS.HOMESCREEN_BG}`}
                  width={"100%"}
                  height={"100%"}
                  className=""
                /> */}
              </div>
            )}
          </div>
          <div className="flex w-full flex-col">
            <div className="flex justify-between max-w-xl mt-2 ml-4 ">
              <img
                src={`${ASSETS.QUIT_BUTTON}`}
                className=" w-10 h-10 "
                onClick={() => {
                  soundUtils.muteAll(true);
                  onExitGame();
                }}
              />
              <div className="flex w-full justify-center -mt-2 -ml-2 pointer-events-none ">
                <img src={`${ASSETS.GAME_NAME}`} className="" />
              </div>
              <div className="mr-4" onClick={onUpdateSound}>
                {isMusicOn === true ? (
                  <img
                    src={`${ASSETS.SOUND_ON_BUTTON}`}
                    className="w-10 h-10 mr-2"
                  />
                ) : (
                  <img
                    src={`${ASSETS.SOUND_OFF_BUTTON}`}
                    className="w-10 h-10 mr-2"
                  />
                )}
              </div>
            </div>
            <div className="flex w-full h-[15vh] flex-col max-w-xl items-center justify-center ">
              <div className="text-center font-bold text-[2.5vh] text-[#193FA8]">
                Hello, {user?.data.username}
              </div>
              <div className="flex w-full h-full justify-center items-center">
                <ProgressBar
                  totalScreen={user?.progressData.wordSearchPassLvl as number}
                />
              </div>
            </div>
            <div className="flex font-bold text-[2.5vh] mt-[2vh] text-[#193FA8] max-w-xl justify-center items-center ">
              You have: {user?.progressData.wordSearchScore || 0}
              <span>
                <img src="uiAsset/PointIcon.png" className="h-8" />
              </span>
            </div>
            <div className="flex w-full justify-center items-center absolute bottom-3 max-w-xl ">
              <div className="flex flex-col w-[97%] justify-center items-center">
                {dayjs().isBefore(dayjs("2024-02-05")) ? (
                  <img
                    src={`${ASSETS.PLAY_NOW_BUTTON}`}
                    className=" w-[60%] active:scale-90 transition"
                    onClick={handlePlay}
                  />
                ) : (
                  <img
                    src={`uiAsset/PlayDisabled.png`}
                    className=" w-[60%] active:scale-90 transition"
                  />
                )}
                <img
                  src={`${ASSETS.PRIZES_BUTTON}`}
                  className="w-[45%] mt-[3.5%] active:scale-90 transition"
                  onClick={() => setOpenPrizesPopup(true)}
                />
                <img
                  src={`${ASSETS.HANDBOOK_BUTTON}`}
                  className="w-[45%] mt-[3.5%] active:scale-90 transition"
                  onClick={() => setOpenHandBookPopup(true)}
                />
                <img
                  src={`${ASSETS.HOW_TO_PLAY_BUTTON}`}
                  className="w-[45%] mt-[3.5%] active:scale-90 transition"
                  onClick={() => setOpenIntructionPopup(true)}
                />

                <img
                  src={`${ASSETS.LEADERBOARD_BUTTON}`}
                  className="w-[45%] mt-[3.5%] active:scale-90 transition"
                  onClick={() => setOpenLeaderBoardPopup(true)}
                />
                <img src={`${ASSETS.BANK_NAME}`} className="h-10 mt-[3vh]" />
              </div>
            </div>
          </div>
        </div>
      )}
      <SuggestPopup
        isOpen={openSuggestGiftPopup}
        closeModal={() => setOpenSuggestGiftPopup(false)}
        zIndex={999}
      />{" "}
      <SorryPopup
        isOpen={openSorryPopup}
        closeModal={() => {
          setOpenSorryPopup(false);
          setSorryPopup(false);
          setInstructViewed(true);
        }}
      />
      <EndGamePopup
        isOpen={openEndGamePopup}
        closeModal={() => setOpenEndGamePopup(false)}
      />
      <BonusPointPopup
        isOpen={openBonusPointPopup}
        closeModal={() => setOpenBonusPointPopup(false)}
        zIndex={999}
      />
      <PrizesPopup
        isOpen={openPrizesPopup}
        closeModal={() => setOpenPrizesPopup(false)}
        zIndex={999}
      />
      <HandBookPopup
        isOpen={openHandBookPopup}
        closeModal={() => setOpenHandBookPopup(false)}
      />
      <InstructionPopup
        isOpen={openIntructionPopup}
        closeModal={() => setOpenIntructionPopup(false)}
        zIndex={999}
      />
      <AcceptRulePopup
        isOpen={openAcceptRulesPopup}
        closeModal={() => setOpenAcceptRulesPopup(false)}
      />
      <WeekRankPopup
        isOpen={openWeekRankPopup}
        closeModal={() => {
          setOpenWeekRankPopup(false);
          ackNoti();
        }}
        zIndex={999}
        data={weekData}
      />
      <TotalRankPopup
        isOpen={openTotalRankPopup}
        closeModal={() => setOpenTotalRankPopup(false)}
        zIndex={999}
        data={[]}
      />
      <LeaderBoardPopup
        isOpen={openLeaderBoardPopup}
        closeModal={() => setOpenLeaderBoardPopup(false)}
        soundState={isMusicOn}
        handleSound={onUpdateSound}
      />
    </div>
  );
};

export default PlayScreen;
