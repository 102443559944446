import { Line } from "./../types";
import { GameManager } from "../initGame";
import { System } from "../util/sysman";
import { Color, InputEvent, Texture, Vector2 } from "gdxts";
import { OFFSET, dimensionInfo } from "../Constants";
import Direction from "../util/word-search/direction";
import { emitter } from "../util/emitter";
import { toNumber } from "lodash";

const getCellPos = (x: number, y: number) => {
  const cellPos = new Vector2();
  cellPos.set(
    x * (dimensionInfo.dimensions.GRID_SIZE + dimensionInfo.dimensions.BORDER) +
      dimensionInfo.dimensions.PADDING +
      dimensionInfo.dimensions.BORDER,
    y * (dimensionInfo.dimensions.GRID_SIZE + dimensionInfo.dimensions.BORDER) +
      dimensionInfo.dimensions.START_Y +
      dimensionInfo.dimensions.BORDER
  );
  return cellPos;
};

const getCellCoord = (cellPos: Vector2) => {
  const x =
    (cellPos.x -
      dimensionInfo.dimensions.PADDING -
      dimensionInfo.dimensions.BORDER) /
    (dimensionInfo.dimensions.GRID_SIZE + dimensionInfo.dimensions.BORDER);
  const y =
    (cellPos.y -
      dimensionInfo.dimensions.START_Y -
      dimensionInfo.dimensions.BORDER) /
    (dimensionInfo.dimensions.GRID_SIZE + dimensionInfo.dimensions.BORDER);
  return { x, y };
};

const getEndPosWord = (
  startPosGrid: Vector2,
  direction: Direction,
  length: number
) => {
  if (direction === Direction.Vertical) {
    startPosGrid.x += length - 1;
  }
  if (direction === Direction.Horizontal) {
    startPosGrid.y += length - 1;
  }
  if (direction === Direction.DiagonalDown) {
    startPosGrid.x += length - 1;
    startPosGrid.y += length - 1;
  }
  if (direction === Direction.DiagonalUp) {
    startPosGrid.x += length - 1;
    startPosGrid.y -= length - 1;
  }

  const distance = new Vector2(startPosGrid.x, startPosGrid.y);

  return distance;
};

export const InstructModeSystem = (manager: GameManager) => {
  manager.addSystem(async (): Promise<System> => {
    const state = manager.context.state;
    const batch = manager.context.batch;
    const currentGame = manager.context.currentGame;
    const gameConfigs = manager.context.gameConfigs;

    let startDraw = false;
    setTimeout(() => {
      startDraw = true;
    }, 1500);

    const pointer = await Texture.load(
      manager.context.gl,
      "uiAsset/pointerInstruction.png"
    );

    const startPoint = new Vector2();
    const startPointWords = new Vector2();
    const endPointWords = new Vector2();
    const drawPoint = new Vector2(startPoint.x, startPoint.y);

    let directionFirstWord: Direction = 0;
    let speed = 0;
    const maxSpeed = 400;
    const maxDrawSize = new Vector2();
    let indexOfFirstAnswer = 0;

    const CIRCLE_SIZE = dimensionInfo.dimensions.GRID_SIZE * 0.5;
    const circle = manager.context.assets.getTexture("circle")!;
    const whiteTexture = Texture.createWhiteTexture(manager.context.gl);
    const drawLineV1 = new Vector2();
    const drawLineV2 = new Vector2();

    const drawLineExample = (line: Line, color: Color) => {
      let pos = getCellPos(line.x1, line.y1);
      const startX = pos.x + dimensionInfo.dimensions.GRID_SIZE / 2;
      const startY = pos.y + dimensionInfo.dimensions.GRID_SIZE / 2;
      batch.setColor(color);
      batch.draw(
        circle,
        startX - CIRCLE_SIZE / 2 + OFFSET,
        startY - CIRCLE_SIZE / 2,
        CIRCLE_SIZE,
        CIRCLE_SIZE
      );
      pos = getCellPos(line.x2, line.y2);
      const endX = pos.x + dimensionInfo.dimensions.GRID_SIZE / 2;
      const endY = pos.y + dimensionInfo.dimensions.GRID_SIZE / 2;
      batch.draw(
        circle,
        endX - CIRCLE_SIZE / 2 + OFFSET,
        endY - CIRCLE_SIZE / 2,
        CIRCLE_SIZE,
        CIRCLE_SIZE
      );

      const rectCenterX = (startX + endX) / 2;
      const rectCenterY = (startY + endY) / 2;

      batch.draw(
        circle,
        rectCenterX - CIRCLE_SIZE / 2 + OFFSET,
        rectCenterY - CIRCLE_SIZE / 2,
        CIRCLE_SIZE,
        CIRCLE_SIZE
      );

      drawLineV1.set(startX, startY);
      drawLineV2.set(endX, endY);

      const angle = Math.atan2(endY - startY, endX - startX);
      const distance = drawLineV1.distance(drawLineV2);

      batch.draw(
        whiteTexture,
        rectCenterX - distance / 2 + OFFSET,
        rectCenterY - CIRCLE_SIZE / 2,
        distance,
        CIRCLE_SIZE,
        distance / 2,
        CIRCLE_SIZE / 2,
        angle
      );

      batch.setColor(Color.WHITE);
    };

    const inputHandler = manager.context.inputHandler;

    return {
      process(delta: number) {
        if (startDraw && currentGame.instructMode) {
          if (inputHandler.isTouched()) {
            emitter.emit("offInstructMode");
            manager.context.currentGame.instructMode = false;
          }
          if (speed < maxSpeed) {
            speed += 1.75;
          } else {
            speed = 0;
            drawPoint.setVector(startPoint);
          }

          batch.draw(
            pointer,
            drawPoint.x +
              dimensionInfo.dimensions.GRID_SIZE / 2 -
              pointer.width / 2 +
              OFFSET,
            drawPoint.y + dimensionInfo.dimensions.GRID_SIZE / 2,
            pointer.width,
            pointer.height
          );

          const revertDrawPointPos = getCellCoord(drawPoint);
          drawLineExample(
            {
              x1: gameConfigs.correctAllAnswersPos[indexOfFirstAnswer].col,
              y1: gameConfigs.correctAllAnswersPos[indexOfFirstAnswer].row,
              x2: revertDrawPointPos.x,
              y2: revertDrawPointPos.y,
            },
            Color.GREEN
          );

          if (
            directionFirstWord === Direction.Vertical &&
            drawPoint.x < maxDrawSize.x
          ) {
            drawPoint.x += delta * speed;
          }
          drawPoint.x < maxDrawSize.x;
          if (
            directionFirstWord === Direction.Horizontal &&
            drawPoint.y < maxDrawSize.y
          ) {
            drawPoint.y += delta * speed;
          }
          if (
            directionFirstWord === Direction.DiagonalUp &&
            drawPoint.x < maxDrawSize.x &&
            drawPoint.y > maxDrawSize.y
          ) {
            drawPoint.x += delta * speed;
            drawPoint.y -= delta * speed;
          }
          if (
            directionFirstWord === Direction.DiagonalDown &&
            drawPoint.x < maxDrawSize.x &&
            drawPoint.y < maxDrawSize.y
          ) {
            drawPoint.x += delta * speed;
            drawPoint.y += delta * speed;
          }
        } else {
          if (gameConfigs.correctAllAnswersPos.length && state.words) {
            for (const answer in gameConfigs.correctAllAnswersPos) {
              if (
                state.words[0] ===
                gameConfigs.correctAllAnswersPos[answer].wordToPlace
              ) {
                indexOfFirstAnswer = toNumber(answer);
                break;
              }
            }

            startPointWords.set(
              gameConfigs.correctAllAnswersPos[indexOfFirstAnswer].col,
              gameConfigs.correctAllAnswersPos[indexOfFirstAnswer].row
            );
            startPoint.setVector(
              getCellPos(
                gameConfigs.correctAllAnswersPos[indexOfFirstAnswer].col,
                gameConfigs.correctAllAnswersPos[indexOfFirstAnswer].row
              )
            );
            drawPoint.setVector(startPoint);
            directionFirstWord =
              gameConfigs.correctAllAnswersPos[indexOfFirstAnswer].direction;
            endPointWords.setVector(
              getEndPosWord(
                startPointWords,
                directionFirstWord,
                gameConfigs.correctAllAnswersPos[indexOfFirstAnswer].wordToPlace
                  .length
              )
            );
            maxDrawSize.setVector(getCellPos(endPointWords.x, endPointWords.y));
          }
        }
      },
      dispose() {
        emitter.off("offInstructMode", () => {});
      },
    };
  });
};
