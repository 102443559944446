/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from "react";
import gamefoxSDK from "../../../game/util/gameFoxSDK";

enum PrizesVoucherAsset {
  PRIZES_VOUCHER_1M = "uiAsset/PRIZES_VOUCHER_1M.png",
  PRIZES_VOUCHER_800K = "uiAsset/PRIZES_VOUCHER_800K.png",
  PRIZES_VOUCHER_600K = "uiAsset/PRIZES_VOUCHER_600K.png",
  PRIZES_VOUCHER_400K = "uiAsset/PRIZES_VOUCHER_400K.png",
  PRIZES_VOUCHER_200K = "uiAsset/PRIZES_VOUCHER_200K.png",
}

export const Inventory = ({ filterVoucher }) => {
  const [poolsData, setPoolsData] = useState({
    EV_1M: [],
    EV_800K: [],
    EV_600K: [],
    EV_400K: [],
    EV_200K: [],
  });
  const pool = useCallback(async () => {
    const data = await gamefoxSDK.getPoolsStatus();
    console.log(data);
    if (data.length)
      setPoolsData({
        EV_1M: data.filter((item: any) => item?.itemType === "EV_1M"),
        EV_800K: data.filter((item: any) => item?.itemType === "EV_800K"),
        EV_600K: data.filter((item: any) => item?.itemType === "EV_600K"),
        EV_400K: data.filter((item: any) => item?.itemType === "EV_400K"),
        EV_200K: data.filter((item: any) => item?.itemType === "EV_200K"),
      });
  }, []);

  useEffect(() => {
    pool();
  }, [pool]);

  return (
    <div className="w-full absolute max-w-xs ml-4 max-h-[21rem] mt-12 overflow-scroll">
      <div className="flex items-center pr-1 mb-3">
        <img src={PrizesVoucherAsset.PRIZES_VOUCHER_1M} className="h-14" />
        <div className="text-sm mt-1 ml-1">
          <div className="text-blue-800 font-bold">
            eVoucher Got It 1.000.000 VND
          </div>
          <div className="text-gray-500">
            Remaining prizes: {poolsData.EV_1M[0]?.count}/
            {poolsData.EV_1M[0]?.total}
          </div>
        </div>
      </div>
      <div className="flex items-center pr-1 mb-3">
        <img src={PrizesVoucherAsset.PRIZES_VOUCHER_800K} className="h-14" />
        <div className="text-sm mt-1 ml-1">
          <div className="text-blue-800 font-bold">
            eVoucher Got It 800.000 VND
          </div>
          <div className="text-gray-500">
            Remaining prizes: {poolsData.EV_800K[0]?.count || 0}/
            {poolsData.EV_800K[0]?.total || 0}
          </div>
        </div>
      </div>
      <div className="flex items-center pr-1 mb-3">
        <img src={PrizesVoucherAsset.PRIZES_VOUCHER_600K} className="h-14" />
        <div className="text-sm mt-1 ml-1">
          <div className="text-blue-800 font-bold">
            eVoucher Got It 600.000 VND
          </div>
          <div className="text-gray-500">
            Remaining prizes: {poolsData.EV_600K[0]?.count || 0}/
            {poolsData.EV_600K[0]?.total || 0}
          </div>
        </div>
      </div>
      <div className="flex items-center pr-1 mb-3">
        <img src={PrizesVoucherAsset.PRIZES_VOUCHER_400K} className="h-14" />
        <div className="text-sm mt-1 ml-1">
          <div className="text-blue-800 font-bold">
            eVoucher Got It 400.000 VND
          </div>
          <div className="text-gray-500">
            Remaining prizes: {poolsData.EV_400K[0]?.count || 0}/
            {poolsData.EV_400K[0]?.total || 0}
          </div>
        </div>
      </div>
      <div className="flex items-center pr-1 mb-3">
        <img src={PrizesVoucherAsset.PRIZES_VOUCHER_200K} className="h-14" />
        <div className="text-sm mt-1 ml-1">
          <div className="text-blue-800 font-bold">
            eVoucher Got It 200.000 VND
          </div>
          <div className="text-gray-500">
            Remaining prizes: {20 || 0}/{poolsData.EV_200K[0]?.total || 0}
          </div>
        </div>
      </div>
    </div>
  );
};
