import { Vector2 } from "gdxts";
import { dimensionInfo } from "../Constants";
import { Line } from "../types";

const gridPos = new Vector2();
export const calculateGridPos = (x: number, y: number) => {
  const minGrid = 0;
  const maxGrid = 7;
  gridPos.set(
    Math.floor(
      (x - dimensionInfo.dimensions.PADDING - dimensionInfo.dimensions.BORDER) /
        (dimensionInfo.dimensions.GRID_SIZE + dimensionInfo.dimensions.BORDER)
    ),
    Math.floor(
      (y - dimensionInfo.dimensions.START_Y - dimensionInfo.dimensions.BORDER) /
        (dimensionInfo.dimensions.GRID_SIZE + dimensionInfo.dimensions.BORDER)
    )
  );

  if (gridPos.x < minGrid) {
    gridPos.set(minGrid, gridPos.y);
  }
  if (gridPos.x > maxGrid) {
    gridPos.set(maxGrid, gridPos.y);
  }
  if (gridPos.y < minGrid) {
    gridPos.set(gridPos.x, minGrid);
  }
  if (gridPos.y > maxGrid) {
    gridPos.set(gridPos.x, maxGrid);
  }

  return gridPos;
};

export type Mode = "horizontal" | "vertical" | "diagonal";

// calculate the mode of the line using the angle made by the start and end pos
// snap to the nearest mode
export const getMode = (start: Vector2, end: Vector2): Mode => {
  const angle = Math.atan2(end.y - start.y, end.x - start.x);
  const rounded = Math.round((angle / Math.PI) * 4);
  if (rounded === 0 || rounded === 4) return "horizontal";
  if (rounded === 2 || rounded === -2) return "vertical";
  return "diagonal";
};
const line: Vector2[] = [];
export const calculateLine = (
  startPos: Vector2,
  endPos: Vector2,
  output?: Vector2[]
) => {
  output = output || line;
  output.length = 0;
  const mode: Mode = getMode(startPos, endPos);

  if (mode === "horizontal") {
    const xModifer = startPos.x < endPos.x ? 1 : -1;
    let x = startPos.x;

    output.push(new Vector2(x, startPos.y));
    while (x !== endPos.x) {
      x += xModifer;
      output.push(new Vector2(x, startPos.y));
    }
  } else if (mode === "vertical") {
    const yModifer = startPos.y < endPos.y ? 1 : -1;
    let y = startPos.y;
    output.push(new Vector2(startPos.x, y));
    while (y !== endPos.y) {
      y += yModifer;
      output.push(new Vector2(startPos.x, y));
    }
  } else {
    const xModifer = startPos.x < endPos.x ? 1 : -1;
    const yModifer = startPos.y < endPos.y ? 1 : -1;
    let x = startPos.x;
    let y = startPos.y;
    output.push(new Vector2(x, y));
    while (x !== endPos.x && y !== endPos.y) {
      x += xModifer;
      y += yModifer;
      output.push(new Vector2(x, y));
    }
  }
  return output;
};

export const calculateLineCap = (
  startPos: Vector2,
  endPos: Vector2,
  output: Line
) => {
  const mode: Mode = getMode(startPos, endPos);

  if (mode === "horizontal") {
    const xModifer = startPos.x < endPos.x ? 1 : -1;
    let x = startPos.x;

    output.x1 = x;
    output.y1 = startPos.y;
    while (x !== endPos.x) {
      x += xModifer;
    }
    output.x2 = x;
    output.y2 = startPos.y;
  } else if (mode === "vertical") {
    const yModifer = startPos.y < endPos.y ? 1 : -1;
    let y = startPos.y;
    output.x1 = startPos.x;
    output.y1 = y;
    while (y !== endPos.y) {
      y += yModifer;
    }
    output.x2 = startPos.x;
    output.y2 = y;
  } else {
    const xModifer = startPos.x < endPos.x ? 1 : -1;
    const yModifer = startPos.y < endPos.y ? 1 : -1;
    let x = startPos.x;
    let y = startPos.y;
    output.x1 = x;
    output.y1 = y;
    while (x !== endPos.x && y !== endPos.y) {
      x += xModifer;
      y += yModifer;
    }
    output.x2 = x;
    output.y2 = y;
  }
  return output;
};
