import { ASSETS } from "../../../game/util/assets";
import { useStoreState } from "../../../game/util/hook";

interface ModalProps {
  leaderboard: any[];
  position: number;
}

export const TotalRank = ({ leaderboard, position }: ModalProps) => {
  //TODO: Fix score
  const user = useStoreState((state) => state.user);
  return (
    <>
      <div className="flex h-[42vh] w-full flex-col items-center mt-[2.5vh] gap-2  overflow-scroll mb-[2vh]">
        {leaderboard
          ?.sort(
            (a, b) =>
              b.progressData.wordSearchScore - a.progressData.wordSearchScore
          )
          .map((user, index) => (
            <div
              key={index}
              className="flex flex-col justify-center items-center relative"
            >
              {index === 0 && (
                <img src={ASSETS.NAME_TAG_LEVEL_1} className="w-[85%]" />
              )}
              {index === 1 && (
                <img src={ASSETS.NAME_TAG_LEVEL_2} className="w-[85%]" />
              )}
              {index === 2 && (
                <img src={ASSETS.NAME_TAG_LEVEL_2} className="w-[85%]" />
              )}
              {index > 2 && (
                <img src={ASSETS.NAME_TAG_LEVEL_N} className="w-[85%]" />
              )}

              <div className="absolute flex w-[77.5%] ">
                <div className="flex items-center w-full  ">
                  {index + 1}.
                  <img src="uiAsset/FlagLevelIcon.png" className="h-6" />
                  <div className="w-[85%] truncate">
                    {" " + user.data.username}
                  </div>
                  <div className="flex items-center -mr-2">
                    {user.progressData.wordSearchScore}
                    <span>
                      <img
                        src="uiAsset/PointIcon.png"
                        className=" w-[2.55rem]"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="flex w-[85%] items-center">
        <img src="uiAsset/NametagUser.png" />
        <div className="absolute flex w-[77.5%] ml-3">
          <div className="flex justify-between w-full">
            <div className="flex w-full justify-between items-center">
              <div className="flex ">
                <span>{position || 0}. </span>
                <img src="uiAsset/FlagLevelIcon.png" className="h-6" />
                <div>Yours</div>
              </div>
              <div className="flex items-center -mr-2">
                {user.progressData.wordSearchScore || 0}
                <span>
                  <img src="uiAsset/PointIcon.png" className="h-8" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
